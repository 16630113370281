import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertCtrl: AlertController) { }

  async showAlertWithCloseBtn(title: any, message: any) {
    let alert = this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['Close']
    });
    (await alert).present();
  }

  async showAlertWithOkBtn(title : any, message : any) {
    let alert = this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['Ok']
    });
    (await alert).present();
  }
}
