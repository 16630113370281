import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavController, ToastController, ModalController } from '@ionic/angular';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
  forgotPassword = { usernameOrEmail: '' };
  public submitted: boolean = false;
  public errorMessage: string = '';
  public isErrorPresent: boolean = false;
  public successMessage: string = '';
  public isSuccess: boolean = false;

  loading: any;
  constructor(public navCtrl: NavController,
    private httpProvider: HttpProvider,
    private toastCtrl: ToastController,
    private spinner: SpinnerService,
    private modalCtrl: ModalController, 
    private toaster : ToasterService
  ) {
  }
  ngOnInit(): void {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onSend(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      this.spinner.show('Please wait...');
      this.isErrorPresent = false;
      this.isSuccess = false;
      this.httpProvider.forgotPassword(this.forgotPassword.usernameOrEmail).subscribe(async data => {
        this.spinner.hide();
        if (data != null && data.body != null) {
          this.forgotPassword.usernameOrEmail = '';
          this.modalCtrl.dismiss();
          let toast = this.toastCtrl.create({
            message: 'Password reset link sent successfully',
            duration: 3000,
            position: 'top',
            cssClass: "toaster-wrapper",
            color: 'success'
          });
          (await toast).present();
        }
      }, (error: any) => {
        this.spinner.hide();
        if (error != null && error.body != null) {
          var errorData = error.body;
          this.isErrorPresent = true;
          this.errorMessage = errorData[0].Message;
        }        
        else if (error && error.error && error.error[0] && error.error[0].Message){
          this.isErrorPresent = true;
          this.errorMessage = error.error[0].Message;
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      })
    }
  }
}

