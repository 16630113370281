import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() { }
 
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  };

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  };

  removeFavorite(sessionName: string): void {
    let index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  };

  login(username: string): void {
    localStorage.setItem(this.HAS_LOGGED_IN, "true");
    this.setUsername(username);
    //this.events.publish('user:login');
  };

  signup(username: string): void {
    localStorage.setItem(this.HAS_LOGGED_IN, "true");
    this.setUsername(username);
    //this.events.publish('user:signup');
  };

  logout(): void {
    localStorage.removeItem(this.HAS_LOGGED_IN);
    localStorage.removeItem('username');
    localStorage.removeItem('accountId');
    localStorage.removeItem('accounIdStore');
    localStorage.removeItem('storedURL');
    localStorage.removeItem('AuthToken');
    localStorage.removeItem('SportsFocusCodes');
    localStorage.removeItem('athleteIdForUpdateprofile');
    localStorage.removeItem('password');
    localStorage.removeItem('usernameForAuthorization');
    localStorage.removeItem('athleteNames');
    localStorage.removeItem('athleteIdForHU');
    localStorage.removeItem('athleteNameForHU');
    localStorage.removeItem('athleteIdForFS');
    localStorage.removeItem('athleteNameForFS');
    localStorage.removeItem("isCreatedByTAP");
    // this.storage.remove(this.HAS_LOGGED_IN);
    // this.storage.remove('username');
    //this.events.publish('user:logout');
  };

  setUsername(username: string): void {
    localStorage.setItem('username', username);
    //this.storage.set('username', username);
  };

  getUsername() {
    var username = localStorage.getItem("username");
    return username;
    // return this.storage.get('username').then((value) => {
    //   return value;
    // });
  };

  hasLoggedIn(): boolean {
    var hasLoggedIn = localStorage.getItem(this.HAS_LOGGED_IN);
    return hasLoggedIn == "true"
    // return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
    //   return value === true;
    // });
  };

  validateEnteredDatas(event: any, data: any): boolean {
    if (data.length == 0) {
      if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122)) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122) || event.keyCode == 32 || event.keyCode == 46) {
        return true
      }
      else {
        return false
      }
    }
  }

  emailValidationFunction(event: any) {
    if (event != null && event.currentTarget != null && event.currentTarget.value != null) {
      var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let isValid = regex.test(event.currentTarget.value);
      return !isValid;
    }
    else {
      return true;
    }
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  getAccountId() {
    var accntId: any = {};
    try {
      accntId = localStorage.getItem("accountId") || "";
    } catch (e) {
      accntId = "";
    }
    return accntId;
  }

  validateEnteredData(event: any) {
    if (event != null && event.currentTarget != null && event.currentTarget.value != null) {
      var regex = /^[a-zA-Z]+((\s)|(\.)|(?:\s+[a-zA-Z]+)|(?:\.+[a-zA-Z]+))*$/;
      let isValid = regex.test(event.currentTarget.value);
      return !isValid;
    }
    else {
      return true;
    }
  }
}
