import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { PrivacyPolicyPage } from '../privacy-policy/privacy-policy.page';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';
import { TermsAndConditionsPage } from '../terms-and-conditions/terms-and-conditions.page';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {

  isHidePolicy: Boolean = false;
  footerYear: any = new Date().getFullYear();
  isKeyboardOpen: boolean = false;
  resetPassword = { newPassword: '', confirmPassword: '' };
  public submitted: boolean = false;
  public successMessage: string = '';
  public isSuccess: boolean = false;
  public errorMessage: string = '';
  public isErrorPresent: boolean = false;
  public resetPasswordKey: string = '';
  loading: any;
  applicationType: string = "1";
  isMobileView: boolean = false;
  webSize: boolean = false;

  @ViewChild('resetPasswordForm') resetPasswordForm: NgForm | any;
  constructor(private httpProvider: HttpProvider,
    private spinner: SpinnerService,
    private modalCtrl: ModalController,
    public platform: Platform,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private ref: ChangeDetectorRef) {
      if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
        this.isMobileView = false;
        this.webSize = true;
        setTimeout(() => {
          var resetBlock = document.getElementById(
            "reset-block"
          ) as HTMLInputElement;
          resetBlock.classList.add("resetBlockWeb");
          resetBlock.classList.remove("resetBlockMbl");
        }, 100);
      }
      else {
        this.isMobileView = true;
        setTimeout(() => {
          var resetBlock = document.getElementById(
            "reset-block"
          ) as HTMLInputElement;
          resetBlock.classList.add("resetBlockMbl");
          resetBlock.classList.remove("resetBlockWeb");
        }, 100);
      }
      if (this.webSize == true) {
        if (window.matchMedia('(max-width: 800px)').matches) {
          this.isMobileView = true;
          
        setTimeout(() => {
          var resetBlock = document.getElementById(
            "reset-block"
          ) as HTMLInputElement;
          resetBlock.classList.add("resetBlockMbl");
          resetBlock.classList.remove("resetBlockWeb");
        }, 100);
        }
      }
  }

  ngOnInit() {
    this.resetFooterOnKeyboardOpen();
    this.activatedRoute.queryParams.subscribe(params => {
      if ((this.router.getCurrentNavigation() as any).extras.state) {
        var resetKey = (this.router.getCurrentNavigation() as any).extras.state.resetPasswordkey;
        if (resetKey != null && resetKey != undefined && resetKey != "") {
          this.resetPasswordKey = resetKey;
        }
      }
    });
  }

  updatePassword(form: NgForm) {
    this.submitted = true;
    if (form.valid && this.resetPassword.newPassword == this.resetPassword.confirmPassword && this.resetPasswordKey != ''
      && this.resetPasswordKey != undefined && this.resetPasswordKey != null) {
      this.spinner.show('Please wait...');
      this.isSuccess = false;
      this.submitted = false;
      this.httpProvider.resetPassword(this.resetPassword.newPassword, this.resetPasswordKey, this.applicationType).subscribe((data: any) => {
        this.resetPasswordForm.reset();
        this.spinner.hide();
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData[0].isSucess = true) {
            this.isSuccess = true;
            this.successMessage = resultData[0].Message;
            this.router.navigate(['login'], { replaceUrl: true });
            this.toasterService.showSuccessToast("Password successfully updated");
          }
        }
      }, (error: any) => {
        this.spinner.hide();
        this.resetPasswordForm.reset();
        if (error != null && error.body != null) {
          var errorData = error.body;
          this.isErrorPresent = true;
          this.toasterService.showFailureToast(errorData);
        }
        else if (error != null && error.error != null && error.error[0] != null && error.error[0].Message != null) {
          this.toasterService.showFailureToast(error.error[0].Message);
        }
        else {
          this.isErrorPresent = true;
          this.errorMessage = error.error[0].Message;
        }
      })
    }
  }


  async clickTermsandconditions() {
    let modal = await this.modalCtrl.create({ component: TermsAndConditionsPage });
    modal.present();
  }

  async clickPrivacyPolicy() {
    let modal = await this.modalCtrl.create({ component: PrivacyPolicyPage });
    modal.present();
  }

  resetFooterOnKeyboardOpen() {
    window.addEventListener('keyboardWillShow', (e) => {
      this.isKeyboardOpen = true;
      setTimeout(() => {
        this.ref.detectChanges()
      }, 100);
    });

    window.addEventListener('keyboardWillHide', () => {
      this.isKeyboardOpen = false;
      setTimeout(() => {
        this.ref.detectChanges()
      }, 100);
    });
  }
}
