import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { HttpProvider } from "./providers/http-provider.service";

import { HomelayoutComponent } from "./_layout/homelayout/homelayout.component";
import { AuthguardGuard } from "./providers/authguard.guard";
import {
  AutoCompleteModule,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { CommonlayoutComponent } from "./_layout/commonlayout/commonlayout.component";
import { CommonModule } from "@angular/common";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import {
  MaskedTextBoxModule,
  NumericTextBoxModule,
} from "@syncfusion/ej2-angular-inputs";
import { SpinnerService } from "./providers/spinner-service.service";
import { WebApiService } from "./providers/web-api-service.service";
import { AccountService } from "./providers/account-service.service";
import { BirthDateProvider } from "./providers/birth-date.service";
import { FormsModule } from "@angular/forms";
import {
  DatePickerModule,
  DateTimePickerModule,
  DateRangePickerAllModule,
} from "@syncfusion/ej2-angular-calendars";
import { ChartAllModule } from "@syncfusion/ej2-angular-charts";
import { TabModule } from "@syncfusion/ej2-angular-navigations";
import { ScheduleAllModule } from "@syncfusion/ej2-angular-schedule";
import { LoginPageRoutingModule } from "./login/login-routing.module";
import { AccountSettingsPageModule } from "./account-settings/account-settings.module";
import { ViewProfilePageModule } from "./view-profile/view-profile.module";
import { AddAthletePageModule } from "./add-athlete/add-athlete.module";
import { FeaturePackagePageModule } from "./feature-package/feature-package.module";
import { ForgotPasswordPageModule } from "./forgot-password/forgot-password.module";
import { ResetPasswordPageModule } from "./reset-password/reset-password.module";
import { ContextMenuPageModule } from "./context-menu/context-menu.module";
import { CategoryModelPageModule } from "./category-model/category-model.module";


@NgModule({
  declarations: [AppComponent, HomelayoutComponent, CommonlayoutComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    IonicModule.forRoot({ scrollPadding: false, swipeBackEnabled: false }),
    AppRoutingModule,
    HttpClientModule,
    MultiSelectModule,
    MaskedTextBoxModule,
    DatePickerModule,
    MultiSelectModule,
    TabModule,
    NumericTextBoxModule,
    DateTimePickerModule,
    ScheduleAllModule,
    AutoCompleteModule,
    ChartAllModule,
    DateRangePickerAllModule,
    LoginPageRoutingModule,
    AccountSettingsPageModule,
    ViewProfilePageModule,
    AddAthletePageModule,
    FeaturePackagePageModule,
    ForgotPasswordPageModule,
    ResetPasswordPageModule,
    ContextMenuPageModule,
    CategoryModelPageModule,
  ],
  providers: [
    SQLite,
    StatusBar,
    ScreenOrientation,
    InAppBrowser,
    SpinnerService,
    BirthDateProvider,
    WebApiService,
    AccountService,
    AuthguardGuard,
    HttpProvider,
    AndroidPermissions,
    LocationAccuracy,
    FileTransfer,
    File,
    SocialSharing,
    Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
