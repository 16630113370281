import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImagecropperComponent } from './imagecropper.component';
import { HomeFooterModule } from 'src/app/home-footer/home-footer.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomeFooterModule
    ],
    declarations: [ImagecropperComponent],
    exports: [ImagecropperComponent]
})
export class ImagecropperModule {
}