import {
  OnInit,
  Component,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  Platform,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
} from "@ionic/angular";
import { IAccountLogin } from "../interfaces/account-interface";
import { HttpProvider } from "../providers/http-provider.service";
import { AccountService } from "../providers/account-service.service";
import { NetworkProvider } from "../providers/network.service";
import { Title } from "@angular/platform-browser";
import { NavigationExtras, Router } from "@angular/router";
import { ForgotPasswordPage } from "../forgot-password/forgot-password.page";
import { PrivacyPolicyPage } from "../privacy-policy/privacy-policy.page";
import { TermsAndConditionsPage } from "../terms-and-conditions/terms-and-conditions.page";
import { SpinnerService } from "../providers/spinner-service.service";
import { DataSharingService } from "../providers/data-sharing.service";
import { ToasterService } from "../providers/toaster-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  accountLogin: IAccountLogin = { username: "", password: "" };
  submitted = false;
  strengthenedPassword: string;
  PasswordValidationMessage: string;
  EmailValidationMessage: string;
  loading: any;
  isSinginError = false;
  errorMessage = "";
  isAlreadyLoggedIn: Boolean = false;
  isMobile: Boolean = false;
  subFooterHeight: any;
  isHidePolicy: Boolean = false;
  footerYear: any = new Date().getFullYear();
  isKeyboardOpen: boolean = false;
  gridElement: HTMLElement | null = null;
  isIPadLandscape: boolean = false;
  currentOrientation: string = "";
  orientationChangeTimer: any = null;
  isKeyboardShowing: boolean = true;
  isMobileView: boolean = false;
  webSize: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    clearTimeout(this.orientationChangeTimer);
    this.orientationChangeTimer = setTimeout(() => {
      this.checkIsDeviceIPad();
    }, 300);
  }

  constructor(
    public platform: Platform,
    private alertCtrl: AlertController,
    public accountService: AccountService,
    public httpProvider: HttpProvider,
    public modalCtrl: ModalController,
    private titleService: Title,
    public networkProvider: NetworkProvider,
    private router: Router,
    private spinner: SpinnerService,
    private ref: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private toaster: ToasterService
  ) {
    
    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      this.isMobileView = false;
      this.webSize = true;
    }
    else {
      this.isMobileView = true;
    }
    if (this.webSize == true) {
      if (window.matchMedia('(max-width: 800px)').matches) {
        this.isMobileView = true;
      }
    }
    this.EmailValidationMessage = "";
    this.strengthenedPassword = "";
    this.PasswordValidationMessage = "";

    if (this.platform.is("android") || this.platform.is("ios")) {
      this.isMobile = true;
    }

    if (this.platform.is("ios")) {
      this.subFooterHeight = "100px";
    }
    if (this.platform.is("android")) {
      this.subFooterHeight = "70px";
    }

    this.titleService.setTitle("Login | iPost Sports");

    this.dataSharingService.isGoBackToLogin.subscribe((value) => {
      if (value) {
        this.submitted = false;
        this.Reset();
      }
    });
    window.addEventListener("ionKeyboardDidShow", () => {
      this.isKeyboardShowing = true;
    });
    window.addEventListener("ionKeyboardDidHide", () => {
      this.isKeyboardShowing = false;
    });
  }

  ngOnInit(): void {
    this.isAlreadyLoggedIn = this.accountService.hasLoggedIn();
    if (this.isAlreadyLoggedIn === true) {
      var url = localStorage.getItem("storedURL") || "";
      if (url.match("profile")) {
        this.router.navigate(["profile/view"], { replaceUrl: true });
      } else if (url.match("package")) {
        this.router.navigate(["package"], { replaceUrl: true });
      } else if (url.match("memberaccount/type")) {
        this.router.navigate(["type"]);
      } else if (url.match("memberaccount/create")) {
        this.router.navigate(["member-account-creation"]);
      } else if (url.match("settings")) {
        this.router.navigate(["settings"], { replaceUrl: true });
      }
    }
  }

  onLogin(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      this.isSinginError = false;
      this.spinner.show("Please wait...");
      this.httpProvider.login(this.accountLogin).subscribe(
        (data: any) => {
          this.spinner.hide();
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData[0].IsSuccess) {
              var accountId = resultData[1].account_id;
              localStorage.setItem("password", resultData[1].password);
              localStorage.setItem("loggedonuser", resultData[1].username);
              localStorage.setItem("AuthToken", resultData[2]);
              localStorage.setItem("accountId", accountId);
              localStorage.setItem("accounIdStore", accountId);
              localStorage.setItem("type", resultData[1].account_type);
              localStorage.setItem("waiverfield", resultData[1].waiver_field);
              if (resultData[3] != null && resultData[3].length > 0) {
                localStorage.setItem(
                  "athleteNames",
                  JSON.stringify(resultData[3])
                );
              }
              if (resultData[0].IsCreatedByTAP == true) {
                this.router.navigate(["type"]);
                localStorage.setItem("ispackDisable", "true");
                localStorage.setItem("accountSettingsPage", "true");
                localStorage.setItem("isCreatedByTAP", "true");
                this.accountService.login(this.accountLogin.username);
              } else if (resultData[0].IsAlreadySigned == false) {
                this.router.navigate(["type"]);
                localStorage.setItem("ispackDisable", "true");
                localStorage.setItem("accountSettingsPage", "true");
                this.accountService.login(this.accountLogin.username);
              } else if (resultData[0].IsSubscribed == false) {
                this.router.navigate(["package"], { replaceUrl: true });
                localStorage.setItem("ispackDisable", "false");
                localStorage.setItem("accountSettingsPage", "false");
                localStorage.setItem("storedURL", "adminsite");
                this.accountService.login(this.accountLogin.username);
              } else {
                this.router.navigate(["profile/view"], { replaceUrl: true });
                localStorage.setItem("ispackDisable", "false");
                localStorage.setItem("accountSettingsPage", "false");
                this.accountService.login(this.accountLogin.username);
              }
            } else {
              this.isSinginError = true;
              this.errorMessage = resultData[0].Message;
            }
          }
        },
        (error: any) => {
          this.spinner.hide();
          if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          } else {
            this.alertCtrl.create({
              header: "Error",
              message:
                error.statusText != "" && error.statusText != null
                  ? error.statusText
                  : "Unknown Error",
              buttons: ["OK"],
            });
          }
        }
      );
    }
  }

  onSignup() {
    this.router.navigate(["signup"], { replaceUrl: true });
  }

  Reset() {
    this.accountLogin.username = "";
    this.accountLogin.password = "";
  }

  async ForgetPassword() {
    let modal = await this.modalCtrl.create({ component: ForgotPasswordPage });
    await modal.present();
  }

  resetFooterOnKeyboardOpen() {
    window.addEventListener("keyboardWillShow", (e) => {
      this.isKeyboardOpen = true;
      setTimeout(() => {
        this.ref.detectChanges();
      }, 100);
    });

    window.addEventListener("keyboardWillHide", () => {
      this.isKeyboardOpen = false;
      setTimeout(() => {
        this.ref.detectChanges();
      }, 100);
    });
  }

  onInputFocusEvent() {
    this.gridElement = document.querySelector(".grid-align") as HTMLElement;
    this.checkIsDeviceIPad();
    setTimeout(() => {
      if (this.isIPadLandscape && this.isKeyboardShowing == true) {
        this.gridElement?.classList.add("override-margin-top");
      }
    }, 600);
  }

  onInputBlurEvent() {
    this.gridElement = document.querySelector(".grid-align") as HTMLElement;
    this.checkIsDeviceIPad();
    setTimeout(() => {
      if (this.isIPadLandscape && this.isKeyboardShowing == false) {
        this.gridElement?.classList.remove("override-margin-top");
      }
    }, 300);
  }

  checkIsDeviceIPad() {
    const aspectRatio = window.innerWidth / window.innerHeight;
    if (aspectRatio >= 1) {
      this.currentOrientation = "landscape";
    } else {
      this.currentOrientation = "portrait";
    }
    if (this.platform.is("ipad") && this.currentOrientation === "landscape") {
      this.isIPadLandscape = true;
    } else {
      this.isIPadLandscape = false;
      this.gridElement?.classList.remove("override-margin-top");
    }
  }
}
