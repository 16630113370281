import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthguardGuard } from "../providers/authguard.guard";
import { ViewProfilePage } from "./view-profile.page";

const routes: Routes = [
  {
    path: "",
    component: ViewProfilePage,
    canActivate: [AuthguardGuard],
  },
  {
    path: "profile/view",
    component: ViewProfilePage,
    canActivate: [AuthguardGuard],
  },
  {
    path: "profile/edit",
    component: ViewProfilePage,
    canActivate: [AuthguardGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewProfilePageRoutingModule {}
