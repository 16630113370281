import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthguardGuard } from '../providers/authguard.guard';

import { CategoryModelPage } from './category-model.page';

const routes: Routes = [
  {
    path: '',
    component: CategoryModelPage,
    canActivate: [AuthguardGuard],
  },
  {
    path: "category-model",
    component: CategoryModelPage,
    canActivate: [AuthguardGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoryModelPageRoutingModule {}
