import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { EditSettingsModel, GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { BraintreeModelPage } from '../braintree-model/braintree-model.page';
import { CancelSubscriptionModelPage } from '../cancel-subscription-model/cancel-subscription-model.page';
import { DowngradeConditionsModelPage } from '../downgrade-conditions-model/downgrade-conditions-model.page';
import { EyePostCancelModelPage } from '../eye-post-cancel-model/eye-post-cancel-model.page';
import { IAthlete, IGridDetails, IOnLoadFeaturePackage, ISubscriptionPackage } from '../interfaces/account-interface';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { DataSharingService } from '../providers/data-sharing.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';
import { SwitchPlanCancelModelPage } from '../switch-plan-cancel-model/switch-plan-cancel-model.page';
import { SwitchPlanModelPage } from '../switch-plan-model/switch-plan-model.page';
import { WaiverModelPage } from '../waiver-model/waiver-model.page';

@Component({
  selector: 'app-feature-package',
  templateUrl: './feature-package.page.html',
  styleUrls: ['./feature-package.page.scss'],
})
export class FeaturePackagePage implements OnInit {
  atheleteId: string="";
  message: string = "";
  featurePackageOnLoad: IOnLoadFeaturePackage = {
    username: '',
    email: '',
    id: ''
  };
  footerYear: any = new Date().getFullYear();
  accountId: any;
  RookieButtonActiveHeight: any;
  EliteButtonActiveHeight: any;
  ProButtonActiveHeight: any;
  AthleteProPackageActive: any;
  sum: any = 0;
  submitted = false;
  isWaiverfield: any;
  isParent: Boolean = false;
  rookieActive: Boolean = false;
  eliteActive: Boolean = false;
  proActive: Boolean = false;
  PackageActiveBox: Boolean = false;
  isMobileView: Boolean = false;
  ActivationMessage: string = "";
  sessionPackageExpired: any;
  webSize: Boolean = false;
  parentSubscriptionActive : Boolean = false;
  isAlreadyLoggedIn: Boolean = false;
  GridCash: any;
  AllButtonsHide: Boolean = false;
  alertBackgroundColor: any;
  bannerText_CloseIconColor: any;
  bannerCloseIconColor: any;
  addOnPackageName: any;
  subPackageListRookie: any;
  subPackageListElite: any;
  subPackageListPro: any;
  subPackageListEyePost: any;
  subPackageNameBasic: any;
  subPackageNameBaseBallOrSoftBall: any;
  subPackageNamePrepScoutPost: any;
  subPackageNameEyePost: any;
  subPackageBasicAmount: any;
  subPackageBaseBallOrSoftBallAmount: any;
  subPackagePrepScoutPostAmount: any;
  subPackageNameEyePostAmount: any;
  yearsubPackageBasicAmount: any;
  yearsubPackageBaseBallOrSoftBallAmount: any;
  yearsubPackagePrepScoutPostAmount: any;
  yearsubPackageNameEyePostAmount: any;
  yearsubPackageNameBasic: any;
  yearsubPackageNameBaseBallOrSoftBall: any;
  yearsubPackageNamePrepScoutPost: any;
  yearsubPackageNameEyePost: any;
  yearsubPackageListRookie: any;
  yearsubPackageListElite: any;
  yearsubPackageListPro: any;
  yearsubPackageListEyePost: any;
  totalData: any;
  public data: { AthleteName: string, MonthAmount: number, YearAmount: number, isAddOnActive: boolean, value: string }[] | any;
  public gridDataStore: Object[] | any;
  public packageListStore: Object[] | any;
  gridDetails: IGridDetails[] | any;
  public selectOptions: any;
  currentActivationPackage: string = "";
  currentAthleteActivationPackageCode: string = "";
  showSubscriptionCancel: Boolean = false;
  public showAddOn: Boolean = false;
  public showAddOnButton: Boolean = false;
  public isAddOnActive: Boolean = false;
  public isMonthlyPlan: Boolean = true;
  public isSubscribed: Boolean = false;
  @ViewChild('grid')
  public grid: GridComponent | any;
  @ViewChild('Waiver') public checkbox: CheckBoxComponent | any;
  public selectedRow: Object  | any;
  public editSettings: EditSettingsModel  | any;
  public packageList: Object[] | any = [];
  public dropdownPackageList: Object[] = [];
  isMonth: boolean = true;
  isYear: boolean = false;
  isMonthAmount: boolean = true;
  isYearAmount: boolean = false;

  public disableItems = ['6'];
  public fields: Object = {
    text: 'Text', value: 'id',
    itemCreated: (e: any) => {
      if (this.disableItems.indexOf(e.curData.id) > -1) {
        e.item.classList.add('e-disable-list-item');
        e.item.classList.add('e-disabled');
      }
    }
  };
  public height: string = '220px';
  public waterMark: string = 'Select a package';
  public value: string = '3';

  athletes: IAthlete[] | any;
  subcriptionPackage: ISubscriptionPackage[] | any;

  @ViewChild('addOnCheckbox')
  public addOnCheckbox: CheckBoxComponent | any;
  constructor(public httpProvider: HttpProvider, private router: Router, private toaster : ToasterService,
    public popOverCtrl: PopoverController, public modalCtrl: ModalController,
    private loadingCtrl: LoadingController, public plt: Platform, private alertCtrl: AlertController,
    private route: ActivatedRoute, private titleService: Title, private dataSharingService: DataSharingService,
    private spinner: SpinnerService, private accountService : AccountService) {
      if (!this.plt.is('ios') && !this.plt.is('android')) {
        this.isMobileView = false;
        this.webSize = true;
      }
      else {
        this.isMobileView = true;
      }
      if (this.webSize == true) {
        if (window.matchMedia('(max-width: 800px)').matches) {
          this.isMobileView = true;
        }
      }
    
    this.isAlreadyLoggedIn = this.accountService.hasLoggedIn();
    if (this.isAlreadyLoggedIn === true) {
      localStorage.setItem('storedURL', 'package');
    }

    localStorage.setItem('accountSettingsPage', "false");
    this.setPackage();

    this.route.queryParams.subscribe(params => {
      if ((this.router.getCurrentNavigation() as any).extras.state) {
        this.isMonth = true;
        this.isYear = false;
        this.isMonthAmount = true;
        this.isYearAmount = false;
        this.isMonthlyPlan = true;
        this.accountId = (this.router.getCurrentNavigation() as any).extras.state.data;
        this.FeaturePackageOnLoad();
      }
    });

    let waiverfield = (localStorage.getItem('waiverfield') || '');
    if (waiverfield != null) {
      this.isWaiverfield = waiverfield == "1" ? true : false;
    }
    else {
      this.isWaiverfield = false;
    }
  }

  public async onPackageChange(args: any, data: any): Promise<void> {
    this.spinner.show("Please wait..");
    this.getMonthlyPlan(data);
    if (data.selectedPackageCode != null) {
      if (args.detail.value == data.selectedPackageCode) {
        if (data.defaultAddOnActive != true) {
          if (data.isAddOnActive != true) {
            data.Amount = 0;
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = 0;
              }
              else {
                data.YearAmount = 0;
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = 0;
              }
              else {
                data.YearAmount = 0;
              }
            }
            data.value = args.detail.value;
            data.selectedPackage = this.getPackageDescription(args.detail.value);
          }
        }
        else {
          data.Amount = 0;
          if (data.subscription_duration != null) {
            if (data.subscription_duration == 1) {
              data.MonthAmount = 0;
            }
            else {
              data.YearAmount = 0;
            }
          }
          else {
            if (this.isMonthlyPlan) {
              data.MonthAmount = 0;
            }
            else {
              data.YearAmount = 0;
            }
          }
          data.value = args.detail.value;
          data.selectedPackage = this.getPackageDescription(args.detail.value);
        }
      }
      else {
        if (data.selectedPackageCode == 6 || (data.selectedPackageCode == 5 && args.detail.value == 4)) {
          let detail = {
            loggedType: 'ParentType'
          }
          let modal = await this.modalCtrl.create({
            component: DowngradeConditionsModelPage,
            componentProps: detail,
            cssClass: 'stripe-modal'
          });;
          await modal.present();
        }
        if (!data.defaultAddOnActive) {
          if (data.isAddOnActive != true) {
            data.Amount = this.getPackageAmount(args.detail.value, false);
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = this.getMonthAmount(data, args, null);
              }
              else {
                data.YearAmount = this.getYearAmount(data, args, null);
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = this.getMonthAmount(data, args, null);
              }
              else {
                data.YearAmount = this.getYearAmount(data, args, null);
              }
            }
            data.value = args.detail.value;
            data.selectedPackage = this.getPackageDescription(args.detail.value);
          }
        }
        else {
          data.Amount = this.getPackageAmount(args.detail.value, false);
          if (data.subscription_duration != null) {
            if (data.subscription_duration == 1) {
              data.MonthAmount = this.getMonthAmount(data, args, null);
            }
            else {
              data.YearAmount = this.getYearAmount(data, args, null);
            }
          }
          else {
            if (this.isMonthlyPlan) {
              data.MonthAmount = this.getMonthAmount(data, args, null);
            }
            else {
              data.YearAmount = this.getYearAmount(data, args, null);
            }
          }
          data.value = args.detail.value;
          data.selectedPackage = this.getPackageDescription(args.detail.value);
        }
      }
    }
    else {
      if (data.defaultAddOnActive != true) {
        if(data.isAddOnActive != true){
          data.Amount = this.getPackageAmount(args.detail.value, false);
          if (data.subscription_duration != null) {
            if (data.subscription_duration == 1) {
              data.MonthAmount = this.getMonthAmount(data, args, null);
            }
            else {
              data.YearAmount = this.getYearAmount(data, args, null);
            }
          }
          else {
            if (this.isMonthlyPlan) {
              data.MonthAmount = this.getMonthAmount(data, args, null);
            }
            else {
              data.YearAmount = this.getYearAmount(data, args, null);
            }
          }
          data.value = args.detail.value;
          data.selectedPackage = this.getPackageDescription(args.detail.value);
        }
      }
    }
    this.grid.editModule.updateRow(data.index, data);
    this.showAlertForSameDuration(data);
    this.spinner.hide();
  }

  onPackageOpen(e:any) {
    ;
  }

  async adOnParentChange(args: any, data: any): Promise<void> {
    this.spinner.show("Please wait..");

    //this.getMonthlyPlan(data);
    let gridData = this.grid.getSelectedRecords() as any;

    var addOnAmount = null;
    if (data.isAddOnActive == true && data.defaultAddOnActive == false) {
      addOnAmount = this.getPackageAmount("7", false)
    }
    //when package is already activated
    if (data.selectedPackageCode != null) {
      if (data.selectedPackageCode == data.value) {
        if (data.defaultAddOnActive != true) {
          data.isAddOnActive = args.checked;
          if (data.isAddOnActive == true && data.defaultAddOnActive == false) {
            addOnAmount = this.getPackageAmount("7", false);
          }
          if (args.checked == true) {
            data.Amount = addOnAmount;
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = addOnAmount;
              }
              else {
                data.YearAmount = addOnAmount;
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = addOnAmount;
              }
              else {
                data.YearAmount = addOnAmount;
              }
            }
          }
          else {
            data.Amount = this.removeAddOnPackageAmount(data.Amount);
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
              }
              else {
                data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
              }
              else {
                data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
              }
            }
          }
        }
        else {
          if (!args.checked) {
            localStorage.setItem('packageListStore', JSON.stringify(this.packageList));
            localStorage.setItem('isMonthlyPlan', JSON.stringify(this.isMonthlyPlan));
            localStorage.setItem('gridDataStore', JSON.stringify(gridData));
            let value = {
              cash: "$" + this.getPackageAmount("7", false),
              atheleteId: data.athelete_Id,
              athleteOldPackageCode: data.selectedPackageCode,
              packageCode: "7",
              packageDescription: this.getPackageDescription('7'),
              user: 'parent',
              isAddOnActive: data.isAddOnActive
            }
            let modal = await this.modalCtrl.create({
              component: EyePostCancelModelPage,
              cssClass: 'stripe-modal',
              componentProps: value
            });
            modal.onWillDismiss().then((e : any) => {
              if (e != undefined && e != null && e == "Proceed") {
                data.defaultAddOnActive = false;
                data.isAddOnActive = false;
                this.grid.editModule.updateRow(data.index, data);
                let navigationExtras: NavigationExtras = { state: { data: true } };
                this.router.navigate(['features'], navigationExtras);
              }
            });
            await modal.present();
          }
        }
      }
      else {
        if (data.defaultAddOnActive != true) {
          data.isAddOnActive = args.checked;
          if (data.isAddOnActive == true && data.defaultAddOnActive == false) {
            addOnAmount = this.getPackageAmount("7", false)
          }
          if (args.checked) {
            data.Amount = this.getPackageAmount(data.value, false);
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 1 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
              }
              else {
                data.YearAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 2 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 1 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
              }
              else {
                data.YearAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 2 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
              }
            }
          } else {
            data.Amount = this.removeAddOnPackageAmount(data.Amount);
            if (data.subscription_duration != null) {
              if (data.subscription_duration == 1) {
                data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
              }
              else {
                data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
              }
            }
            else {
              if (this.isMonthlyPlan) {
                data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
              }
              else {
                data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
              }
            }
          }
        }
        else {
          if (args.checked != true) {
            localStorage.setItem('packageListStore', JSON.stringify(this.packageList));
            localStorage.setItem('isMonthlyPlan', JSON.stringify(this.isMonthlyPlan));
            localStorage.setItem('gridDataStore', JSON.stringify(gridData));
            let value = {
              cash: "$" + this.getPackageAmount("7", false),
              atheleteId: data.athelete_Id,
              athleteOldPackageCode: data.selectedPackageCode,
              packageCode: "7",
              packageDescription: this.getPackageDescription('7'),
              user: 'parent',
              isAddOnActive: data.isAddOnActive
            }
            let modal = await this.modalCtrl.create({ component: EyePostCancelModelPage },);
            await modal.present();
            modal.onWillDismiss().then((e : any) => {
              if (e != undefined && e != null && e == "Proceed") {
                this.showAddOn = true;
                this.showAddOnButton = true;
                let navigationExtras: NavigationExtras = { state: { data: true } };
                this.router.navigate(['features'], navigationExtras);
              }
            });
          }
        }
      }
    }
    else {
      if (data.defaultAddOnActive != true) {
        data.isAddOnActive = args.checked;
        var addOnAmount = null;
        if (data.isAddOnActive == true && data.defaultAddOnActive == false) {
          addOnAmount = this.getPackageAmount("7", false)
        }
        if (args.checked) {
          data.Amount = this.getPackageAmount(data.value, false);
          if (data.subscription_duration != null) {
            if (data.subscription_duration == 1) {
              data.MonthAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 1 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
            }
            else {
              data.YearAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 2 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
            }
          }
          else {
            if (this.isMonthlyPlan) {
              data.MonthAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 1 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
            }
            else {
              data.YearAmount = (data.selectedPackageCode == data.value && data.subscription_duration == 2 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(data.value, false);
            }
          }
        } else {
          data.Amount = this.removeAddOnPackageAmount(data.Amount);
          if (data.subscription_duration != null) {
            if (data.subscription_duration == 1) {
              data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
            }
            else {
              data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
            }
          }
          else {
            if (this.isMonthlyPlan) {
              data.MonthAmount = this.removeAddOnPackageAmount(data.MonthAmount);
            }
            else {
              data.YearAmount = this.removeAddOnPackageAmount(data.YearAmount);
            }
          }
        }
      }
    }
    this.grid.editModule.updateRow(data.index, data);
    this.showAlertForSameDuration(data);
    this.spinner.hide();
  }

  clickbtn() {
    this.router.navigate(['profile/view']);
  }

  setPackage(): void {
    this.subcriptionPackage = [
      { subscription_package_code: 1, subscription_package_descrption: "Rookie", monthly_price: 1, start_up_price: 1 },
      { subscription_package_code: 1, subscription_package_descrption: "Elite", monthly_price: 1, start_up_price: 1 },
      { subscription_package_code: 1, subscription_package_descrption: "Pro", monthly_price: 1, start_up_price: 1 }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Features and Packages | iPost Sports');
    this.editSettings = { allowEditing: false, allowAdding: true, allowDeleting: true };
    this.FeaturePackageOnLoad();
  }

  FeaturePackageOnLoad() {
    this.accountId = this.accountService.getAccountId();
    if (this.accountId != null && this.accountId != undefined && this.accountId != "") {
      this.selectOptions = { persistSelection: true, checkboxOnly: true };
      this.RookieButtonActiveHeight = '100px';
      this.EliteButtonActiveHeight = '100px';
      this.ProButtonActiveHeight = '100px';
      this.AthleteProPackageActive = '80px';
      this.alertBackgroundColor = 'rgba(0, 0, 0, 0.47)';
      this.bannerText_CloseIconColor = '#f4f7fc';
      this.bannerCloseIconColor = 'closeButtonColorActive';
      this.spinner.show('Please wait...');
      this.httpProvider.featurePackageOnLoad(this.accountId).subscribe((data: any) => {
        if (data.ok = true) {
          var details = data.body;
          if (details.message != "undefined" && details.message != "" && details.message != null) {
            if (details.message == "Parent") {
              this.isParent = details.isParent;
              this.data = details.atheleteDetail;
              this.packageList = details.subscriptionDetail;
              this.dropdownPackageList = details.subscriptionDropdownPackageDetails;

              //subPackageCode=4
              this.subPackageListRookie = this.packageList[0];
              this.subPackageNameBasic = this.subPackageListRookie.Text.toUpperCase();
              this.subPackageBasicAmount = this.subPackageListRookie.MonthAmount;
              this.yearsubPackageNameBasic = this.subPackageListRookie.Text.toUpperCase();
              this.yearsubPackageBasicAmount = this.subPackageListRookie.YearAmount;

              //subPackageCode=5
              this.subPackageListElite = this.packageList[1];
              this.subPackageNameBaseBallOrSoftBall = this.subPackageListElite.Text.toUpperCase();
              this.subPackageBaseBallOrSoftBallAmount = this.subPackageListElite.MonthAmount;
              this.yearsubPackageNameBaseBallOrSoftBall= this.subPackageListElite.Text.toUpperCase();
              this.yearsubPackageBaseBallOrSoftBallAmount = this.subPackageListElite.YearAmount;

              //subPackageCode=6
              this.subPackageListPro = this.packageList[2];
              this.subPackageNamePrepScoutPost = this.subPackageListPro.Text.toUpperCase();
              this.subPackagePrepScoutPostAmount = this.subPackageListPro.MonthAmount;
              this.yearsubPackageNamePrepScoutPost = this.subPackageListPro.Text.toUpperCase();
              this.yearsubPackagePrepScoutPostAmount = this.subPackageListPro.YearAmount;

              this.message = details.message;
              if (details.activatedPackage != null && details.activatedPackage != "" && details.activatedPackage != undefined && details.activatedPackage != "Parent Activation" && details.activatedPackage != "No Subscription Found" && details.subscriptionEndDate != null && details.subscriptionEndDate != "" && details.subscriptionEndDate != undefined) {
                this.showSubscriptionCancel = true;
                this.RookieButtonActiveHeight = '50px';
                this.EliteButtonActiveHeight = '50px';
                this.ProButtonActiveHeight = '50px';
              }
              if (details.activatedPackage != null && details.activatedPackage != "" && details.activatedPackage != undefined && details.activatedPackage == "Parent Activation" && details.activatedPackage != "No Subscription Found" && details.subscriptionEndDate != null && details.subscriptionEndDate != "" && details.subscriptionEndDate != undefined) {
                this.showSubscriptionCancel = true;
                this.RookieButtonActiveHeight = '50px';
                this.EliteButtonActiveHeight = '50px';
                this.ProButtonActiveHeight = '50px';
              }
              if (details.activatedPackage != null && details.activatedPackage != "" && details.activatedPackage != undefined && details.activatedPackage != "Parent Activation" && details.activatedPackage == "No Subscription Found") {
                this.RookieButtonActiveHeight = '50px';
                this.EliteButtonActiveHeight = '50px';
                this.ProButtonActiveHeight = '50px';
              }
              this.setButtonStyle();
            }
            else if (details.message == "Athelete") {
              this.isParent = false;
              this.data = details.atheleteDetail;
              this.message = details.message;
              this.atheleteId = details.athelete_Id;
              this.packageList = details.subscriptionDetail;
              this.packageList = details.subscriptionDetail;
              this.dropdownPackageList = details.subscriptionDropdownPackageDetails;

              //subPackageCode=4
              this.subPackageListRookie = this.packageList[0];
              this.subPackageNameBasic = this.subPackageListRookie.Text.toUpperCase();
              this.subPackageBasicAmount = this.subPackageListRookie.MonthAmount;
              this.yearsubPackageNameBasic = this.subPackageListRookie.Text.toUpperCase();
              this.yearsubPackageBasicAmount = this.subPackageListRookie.YearAmount;

              //subPackageCode=5
              this.subPackageListElite = this.packageList[1];
              this.subPackageNameBaseBallOrSoftBall = this.subPackageListElite.Text.toUpperCase();
              this.subPackageBaseBallOrSoftBallAmount = this.subPackageListElite.MonthAmount;
              this.yearsubPackageNameBaseBallOrSoftBall = this.subPackageListElite.Text.toUpperCase();
              this.yearsubPackageBaseBallOrSoftBallAmount = this.subPackageListElite.YearAmount;

              //subPackageCode=6
              this.subPackageListPro = this.packageList[2];
              this.subPackageNamePrepScoutPost = this.subPackageListPro.Text.toUpperCase();
              this.subPackagePrepScoutPostAmount = this.subPackageListPro.MonthAmount;
              this.yearsubPackageNamePrepScoutPost = this.subPackageListPro.Text.toUpperCase();
              this.yearsubPackagePrepScoutPostAmount = this.subPackageListPro.YearAmount;

              localStorage.removeItem('gridDataStore');
              localStorage.removeItem('packageListStore');

              if (details.athletePackageCode == "4") {
                this.rookieActive = true;
                this.PackageActiveBox = true;
                this.currentActivationPackage = details.activatedPackage;
                this.currentAthleteActivationPackageCode = details.athletePackageCode;
                this.ActivationMessage = "ACTIVATED PACKAGE : " + details.activatedPackage.toUpperCase();
              }
              else if (details.athletePackageCode == "5") {
                this.eliteActive = true;
                this.showSubscriptionCancel = true;
                this.PackageActiveBox = true;
                this.currentActivationPackage = details.activatedPackage;
                this.currentAthleteActivationPackageCode = details.athletePackageCode;
                this.ActivationMessage = "ACTIVATED PACKAGE : " + details.activatedPackage.toUpperCase();
              }
              else if (details.athletePackageCode == "6") {
                this.proActive = true;
                this.showSubscriptionCancel = true;
                this.PackageActiveBox = true;
                this.AthleteProPackageActive = '80px';
                this.currentActivationPackage = details.activatedPackage;
                this.currentAthleteActivationPackageCode = details.athletePackageCode;
                this.ActivationMessage = "ACTIVATED PACKAGE : " + details.activatedPackage.toUpperCase();
              }
              this.setButtonStyle();
            }
            else if (details.message == "No Athelete Child Found" || details.message == "No Athelete Found") {
              this.RookieButtonActiveHeight = '50px';
              this.EliteButtonActiveHeight = '50px';
              this.ProButtonActiveHeight = '50px';
              this.AthleteProPackageActive = '80px';
              this.AllButtonsHide = true;
              this.router.navigate(['type']);
              localStorage.setItem('accountSettingsPage', "true");
            }
            else if (details.message == "No Account Found") {
              this.RookieButtonActiveHeight = '50px';
              this.EliteButtonActiveHeight = '50px';
              this.ProButtonActiveHeight = '50px';
              this.AthleteProPackageActive = '80px';
              this.AllButtonsHide = true;
              this.router.navigate(['login'], { replaceUrl: true });
            }
          }
        }
        this.spinner.hide();
      },
        async (error: any) => {
          this.spinner.hide();
          if (error.status == 401) {
          }
          else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          }
          else {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: 'Something went wrong. Please try again later.',
              buttons: ['OK']
            });
            (await alert).present();
            (await alert).onDidDismiss().then(async e => {
              this.spinner.show('Please wait...');
              this.router.navigate(['package']);
              this.spinner.hide();
            });
          }
        });
    }
  }

  setButtonStyle() {
    let id: any = this.accountService.getAccountId();
    this.httpProvider.GetSubscribed(id).subscribe(result => {
      this.isSubscribed = result.body;
      if (this.isSubscribed == false) {
        this.isMonth = false;
        this.isYear = false;
      }
      else {
        this.disableDownandUpgrade();
      }
    }, (error: any) =>{
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  OndocumentClick(e: any) {
    if (e.srcElement != undefined && e.srcElement.className.indexOf("e-ddl") == -1) {
      let collect: any = document.getElementsByClassName("e-dropdownlist");
      for (var i = 0; i < collect.length; i++) {
        collect[i].ej2_instances[0].hidePopup();
        collect[i].ej2_instances[0].focusOut();
      }
    }
  }

  async buyNowTransactionBtn() {
    let gridData = this.grid.getSelectedRecords() as any;
    let subscription_duration;
    if (gridData.length > 0) {
      this.data.forEach((i: any) => {
        if (i.subscription_duration != null) {
          if (i.subscription_duration == 1) {
            this.isMonthlyPlan = true;
          }
          else {
            this.isMonthlyPlan = false;
          }
        }
      });

      gridData = (gridData as any).filter((a : any) => a.YearAmount != 0);
      this.sum = 0;
      gridData.forEach((i: any) => {
        if (i.subscription_duration == null) {
          subscription_duration = null;
        }
        else {
          subscription_duration = i.subscription_duration;
        }
        if (i.subscription_duration != null) {
          if (i.subscription_duration == 1) {
            this.sum += parseFloat(i.MonthAmount);
          }
          else {
            this.sum += parseFloat(i.YearAmount);
          }
        }
        else {
          if (this.isMonthlyPlan) {
            this.sum += parseFloat(i.MonthAmount);
          }
          else {
            this.sum += parseFloat(i.YearAmount);
          }
        }
      });

      this.sum = parseFloat(this.sum).toFixed(2);
      if (this.sum > 0 && this.sum != null && this.sum != undefined) {
        if (this.isSubscribed) {
          if (subscription_duration == null) {
            var gridDataSouce = this.grid.dataSource as any;
            if (gridDataSouce.length > 0) {
              var firstData: any;
              gridDataSouce.forEach((i: any) => {
                if (i.subscription_duration != null) {
                  firstData = i;
                }
              });
              if (firstData.subscription_duration == 1 && this.isYear) {
                let alert = this.alertCtrl.create({
                  header: 'Alert',
                  message: "You are in monthly plan, so new athlete also will be join the same",
                  buttons: ['OK']
                });
                (await alert).onDidDismiss().then(e => {
                  this.SwitchToMonth();
                });
                (await alert).present();
              }
              else if (firstData.subscription_duration == 2 && this.isMonth) {
                let alert = this.alertCtrl.create({
                  header: 'Alert',
                  message: "You are in yearly plan, so new athlete also will be join the same",
                  buttons: ['OK']
                });
                (await alert).onDidDismiss().then(e => {
                  this.SwitchToYear();
                });
                (await alert).present();
              }
              else {
                this.openBraintreeModel(gridData);
              }
            }
            else {
              this.openBraintreeModel(gridData);
            }
          }
          else {
            this.openBraintreeModel(gridData);
          }
        }
        else {
          this.openBraintreeModel(gridData);
        }
      }
      else {
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: 'No changes in subscription. Please choose other package for subscription',
          buttons: ['OK']
        });
        (await alert).present();
      }
    }
    else {
      let alert = this.alertCtrl.create({
        header: 'Message',
        message: 'Please select at least one athlete to proceed',
        buttons: ['OK']
      });
      (await alert).present();
    }
  }

  async openBraintreeModel(gridData: any) {
    this.spinner.show('Please wait...');
    localStorage.setItem('gridDataStore', JSON.stringify(gridData));
    localStorage.setItem('packageListStore', JSON.stringify(this.packageList));
    localStorage.setItem('isMonthlyPlan', JSON.stringify(this.isMonthlyPlan));
    let data = {
      cash: this.sum,
      atheleteId: this.atheleteId,
      user: "parent"
    }
    let modal = await this.modalCtrl.create({
      component: BraintreeModelPage,
      cssClass: 'stripe-modal modelWidth',
      componentProps: data
    });
    await modal.present();
    this.spinner.hide();
    this.sum = 0;
  }

  async cancelTransactionBtn() {
    let gridData = this.grid.getSelectedRecords() as any;
    let gridCurrentData: any = this.grid.currentViewData;
    if (gridData.length > 0) {
      this.getMonthlyPlan(gridData[0]);
      gridData = (gridData as any).filter((a:any) => a.selectedPackageCode != null);
      if (gridData.length > 0) {
        localStorage.setItem('gridDataStore', JSON.stringify(gridData));
        localStorage.setItem('packageListStore', JSON.stringify(this.packageList));
        localStorage.setItem('isMonthlyPlan', JSON.stringify(this.isMonthlyPlan));
        let value =
        {
          user: 'parent',
          isMonthlyPlan: this.isMonthlyPlan
        }
        let modal = await this.modalCtrl.create({
          component: CancelSubscriptionModelPage,
          cssClass: 'stripe-modal',
          componentProps: value
        });
        await modal.present();
        modal.onWillDismiss().then((e : any) => {
          if (e != undefined && e != null && e == "Yes") {
            let amount = this.getPackageAmount("5", false);
            let packageDescription = this.getPackageDescription("5");
            gridData.forEach((i: any) => {
              i.selectedPackageCode = null;
              i.Amount = amount;
              i.value = "5";
              i.selectedPackage = packageDescription;
              i.defaultAddOnActive = false;
              i.isAddOnActive = false;
              this.grid.editModule.updateRow(i.index, i);
            });

            this.totalData = [];
            if (gridCurrentData && gridCurrentData.length > 0) {
              for (var i = 0; i < gridCurrentData.length; i++) {
                if (gridCurrentData[i].selectedPackageCode != null) {
                  this.totalData.push(gridCurrentData[i].selectedPackageCode);
                }
              }
            }
            if (this.totalData.length >= 1) {
              let navigationExtras: NavigationExtras = { state: { data: true } };
              this.router.navigate(['features'], navigationExtras);
            }
            let id: any = localStorage.getItem('accountId');
            if (id != null) {
              this.httpProvider.GetSubscribed(id).subscribe(result => {
                this.isSubscribed = result.body;
                if (this.isSubscribed == false) {
                  this.isMonth = false;
                  this.isYear = false;
                }
                localStorage.setItem("hideMenuPage", result.body);
                this.router.navigate(['package']);
              }, (error: any) => {
                if (error && error.message) {
                  this.toaster.showFailureToast(error.message);
                }
              });
            }
          }
        });
      }
      else {
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: 'No subscription(s) found for the selected athlete(s)',
          buttons: ['OK']
        });
        (await alert).present();
      }
    }
    else {
      let alert = this.alertCtrl.create({
        header: 'Message',
        message: 'Please select at least one athlete to proceed',
        buttons: ['OK']
      });
      (await alert).present();
    }
  }

  
  async freeAthelete() {
    if (this.currentAthleteActivationPackageCode != "4" && this.currentAthleteActivationPackageCode == undefined) {
      var addOnAmount = null;
      if (this.isAddOnActive) {
        addOnAmount = this.getPackageAmount("7", false);
      }
      let data = {
        cash: "$" + this.getPackageAmount("4", false), // '$0.99',
        atheleteId: this.atheleteId,
        athleteOldPackageCode: this.currentAthleteActivationPackageCode,
        packageCode: "4",
        packageDescription: this.getPackageDescription('4'),
        user: 'athlete',
        isAddOnActive: this.isAddOnActive
      }
      let modal = await this.modalCtrl.create({
        component: BraintreeModelPage,
        cssClass: 'stripe-modal modelWidth',
        componentProps: data
      });
      await modal.present();
    }
    else if (this.currentAthleteActivationPackageCode != "4" && this.currentAthleteActivationPackageCode != undefined) {
      var addOnAmount = null;
      if (this.isAddOnActive) {
        addOnAmount = this.getPackageAmount("7", false);
      }
      let detail = {
        loggedType: 'AthleteType',
        cash: "$" + this.getPackageAmount("4", false),
        atheleteId: this.atheleteId,
        athleteOldPackageCode: this.currentAthleteActivationPackageCode,
        packageCode: "4",
        packageDescription: this.getPackageDescription('4'),
        user: 'athlete',
        isAddOnActive: this.isAddOnActive
      }
      let modal = await this.modalCtrl.create({
        component: DowngradeConditionsModelPage,
        cssClass: 'stripe-modal',
        componentProps: detail
      });
      await modal.present();
    }
  }

  async eliteAthelete() {
    if (this.currentAthleteActivationPackageCode != "5" && this.currentAthleteActivationPackageCode != "6" && (this.currentAthleteActivationPackageCode == undefined || this.currentAthleteActivationPackageCode == "4")) {
      let data = {
        cash: "$" + this.getPackageAmount("5", false), // '$4.99',
        atheleteId: this.atheleteId,
        athleteOldPackageCode: this.currentAthleteActivationPackageCode,
        packageCode: "5",
        packageDescription: this.getPackageDescription('5'),
        user: 'athlete',
        isAddOnActive: this.isAddOnActive
      }
      let modal = await this.modalCtrl.create({
        component: BraintreeModelPage,
        cssClass: 'stripe-modal modelWidth',
        componentProps: data
      });
      await modal.present();
    }
    else if (this.currentAthleteActivationPackageCode != "5" && this.currentAthleteActivationPackageCode != "4" && this.currentAthleteActivationPackageCode != undefined && this.currentAthleteActivationPackageCode == "6") {
      let detail = {
        loggedType: 'AthleteType',
        cash: "$" + this.getPackageAmount("5", false),
        atheleteId: this.atheleteId,
        athleteOldPackageCode: this.currentAthleteActivationPackageCode,
        packageCode: "5",
        packageDescription: this.getPackageDescription('5'),
        user: 'athlete',
        isAddOnActive: this.isAddOnActive
      }
      let modal = await this.modalCtrl.create({
        component: DowngradeConditionsModelPage,
        cssClass: 'stripe-modal',
        componentProps: detail
      });
      await modal.present();
    }
  }

  async proAthelete() {
    let data = {
      cash: "$" + this.getPackageAmount("6", false), //'$19.99',
      atheleteId: this.atheleteId,
      athleteOldPackageCode: this.currentAthleteActivationPackageCode,
      packageCode: "6",
      packageDescription: this.getPackageDescription('6'),
      user: 'athlete',
      isAddOnActive: this.isAddOnActive
    }
    let modal = await this.modalCtrl.create({
      component: BraintreeModelPage,
      cssClass: 'stripe-modal modelWidth',
      componentProps: data
    });
    await modal.present();
  }

  async addOnClick() {
    let data = {
      cash: "$" + this.getPackageAmount("7", false),
      atheleteId: this.atheleteId,
      athleteOldPackageCode: this.currentAthleteActivationPackageCode,
      packageCode: "7",
      packageDescription: this.getPackageDescription('7'),
      user: 'athlete',
      isAddOnActive: this.isAddOnActive
    }
    let modal = await this.modalCtrl.create({
      component: BraintreeModelPage,
      cssClass: 'stripe-modal modelWidth',
      componentProps: data
    });
    await modal.present();
  }

  async cancelAddOnClick() {
    let data = {
      cash: "$" + this.getPackageAmount("7", false),
      atheleteId: this.atheleteId,
      athleteOldPackageCode: this.currentAthleteActivationPackageCode,
      packageCode: "7",
      packageDescription: this.getPackageDescription('7'),
      user: 'athlete',
      isAddOnActive: this.isAddOnActive
    }
    let modal = await this.modalCtrl.create({
      component: EyePostCancelModelPage,
      cssClass: 'stripe-modal',
      componentProps: data
    });
    await modal.present();
    modal.onWillDismiss().then((e : any) => {
      if (e != undefined && e != null && e == "Proceed") {
        this.showAddOn = true;
        this.showAddOnButton = true;
        let navigationExtras: NavigationExtras = { state: { data: true } };
        this.router.navigate(['features'], navigationExtras);
      }
    });
  }

  cancelFreeAthlete() {
    let data = {
      atheleteId: this.atheleteId,
      packageCode: "4",
      packageDescription: this.getPackageDescription('4'),
      user: 'athlete'
    }
    this.cancelSingleAthleteProceed(data);
  }

  cancelEliteAthlete() {
    let data = {
      atheleteId: this.atheleteId,
      packageCode: "5",
      packageDescription: this.getPackageDescription('5'),
      user: 'athlete'
    }
    this.cancelSingleAthleteProceed(data);
  }

  cancelProAthlete() {
    let data = {
      atheleteId: this.atheleteId,
      packageCode: "6",
      packageDescription: this.getPackageDescription('6'),
      user: 'athlete'
    }
    this.cancelSingleAthleteProceed(data);
  }

  async cancelSingleAthleteProceed(args:any) {
    let modal = await this.modalCtrl.create({
      component: CancelSubscriptionModelPage,
      cssClass: 'stripe-modal',
      componentProps: args
    });
    await modal.present();
    modal.onWillDismiss().then((e : any) => {
      if (e != undefined && e != null && e == "Proceed") {
        this.rookieActive = false;
        this.eliteActive = false;
        this.proActive = false;
        this.PackageActiveBox = false;
        this.currentAthleteActivationPackageCode = "";
        this.showAddOn = true;
        this.showAddOnButton = false;
        let navigationExtras: NavigationExtras = { state: { data: true } };
        this.router.navigate(['features'], navigationExtras);
      }
    });
  }

  disablePackages(packages: any) {
    if (packages != null) {
      return true;
    }
    else {
      return false;
    }
  }

  dismissAlertMessage() {
    this.PackageActiveBox = false;
  }

  addAthlete() {
    this.router.navigate(['addathlete']);
  }

  getPackageAmount(id: any, isStartAmount:any) {
    let amount: number=0;
    this.packageList.forEach((element: any) => {
      if (element.id == id) {
        if (isStartAmount) {
          amount = element.startAmount;
        }
        else {
          if (this.isMonthlyPlan == true) {
            amount = element.MonthAmount;
          }
          else {
            amount = element.YearAmount;
          }
        }
      }
    });
    return amount;
  }

  removeAddOnPackageAmount(amount:any) {
    var addOnAmount = this.getPackageAmount("7", false);
    amount = (+amount - (+addOnAmount)).toString();
    return amount;
  }

  getPackageDescription(id: any) {
    let packageDescription: string = "";
    this.packageList.forEach((element: any) => {
      if (element.id == id) {
        packageDescription = element.Text;
      }
    });
    return packageDescription;
  }

  changeAddOnHandler(): void {
    if (this.showAddOn) {
      if (this.addOnCheckbox != undefined) {
        this.isAddOnActive = this.addOnCheckbox.checked;
      }
    }
  }

  changeAddOnMobileHandler(): void {
    if (this.showAddOn) {
      this.isAddOnActive = this.isAddOnActive;
    }
  }

  getGridRowDetail(athleteId: any) {
    var rowDetail = (this.grid.dataSource as any).filter((a:any) => a.athelete_Id == athleteId);
    return rowDetail;
  }

  backButtonAction() {
    window.history.back();
  }

  async waiverInfo() {
    if (this.checkbox.checked == true) {
      let data = {
        accountId: this.accountId
      }
      let modal = this.modalCtrl.create({
        component: WaiverModelPage,
        componentProps: data,
        cssClass: 'waiver-modal'
      });
      (await modal).present();
      (await modal).onWillDismiss().then(e => {
        this.checkbox.checked = false;
      });
    }
  }

  SwitchToYear() {
    this.isMonthlyPlan = false;
    var switchToYear: any;
    switchToYear = document.getElementsByClassName("SwitchToYear");
    for (var i = 0; i < switchToYear.length; i++) {
      switchToYear[i].style.background = "#C01E2D";
      switchToYear[i].style.color = "white";
    };
    var switchToMonth: any;
    switchToMonth = document.getElementsByClassName("SwitchToMonth");
    for (var i = 0; i < switchToMonth.length; i++) {
      switchToMonth[i].style.background = "transparent";
      switchToMonth[i].style.color = "#C01E2D";
    }
    this.data.forEach((i: any) => {
      var addOnAmount = 0;
      if (i.isAddOnActive == true && i.defaultAddOnActive == false) {
        addOnAmount = this.getPackageAmount("7", false)
      }
      if (i.selectedPackageCode == i.value) {
        if (i.defaultAddOnActive != true) {
          i.Amount = addOnAmount;
          if (i.subscription_duration != null) {
            if (i.subscription_duration == 1) {
              i.MonthAmount = addOnAmount;
            }
            else {
              i.YearAmount = addOnAmount;
            }
          }
          else {
            if (this.isMonthlyPlan) {
              i.MonthAmount = addOnAmount;
            }
            else {
              i.YearAmount = addOnAmount;
            }
          }
        }
      }
      else {
        if (i.YearAmount != 0) {
          i.YearAmount = this.getPackageAmount(i.value, false);
        }
      }
    });
    this.gridRefresh();
    this.disableDownandUpgrade();
    setTimeout(() => {
      this.isMonthlyPlan = false;
      this.isYear = true;
      this.isYearAmount = true;
      this.isMonth = false;
      this.isMonthAmount = false;
    }, 100);
  }

  SwitchToMonth() {
    this.isMonthlyPlan = true;
    var switchToMonth: any;
    switchToMonth = document.getElementsByClassName("SwitchToMonth");
    for (var i = 0; i < switchToMonth.length; i++) {
      switchToMonth[i].style.background = "#C01E2D";
      switchToMonth[i].style.color = "white";
    };
    var switchToYear: any;
    switchToYear = document.getElementsByClassName("SwitchToYear");
    for (var i = 0; i < switchToYear.length; i++) {
      switchToYear[i].style.background = "transparent";
      switchToYear[i].style.color = "#C01E2D";
    }
    this.data.forEach((i: any) => {
      var addOnAmount = 0;
      if (i.isAddOnActive == true && i.defaultAddOnActive == false) {
        addOnAmount = this.getPackageAmount("7", false)
      }
      if (i.selectedPackageCode == i.value) {
        if (i.defaultAddOnActive != true) {
          i.Amount = addOnAmount;
          if (i.subscription_duration != null) {
            if (i.subscription_duration == 1) {
              i.MonthAmount = addOnAmount;
            }
            else {
              i.YearAmount = addOnAmount;
            }
          }
          else {
            if (this.isMonthlyPlan) {
              i.MonthAmount = addOnAmount;
            }
            else {
              i.YearAmount = addOnAmount;
            }
          }
        }
      }
      else {
        if (i.MonthAmount != 0) {
          i.MonthAmount = this.getPackageAmount(i.value, false);
        }
      }
    });
    this.gridRefresh();
    this.disableDownandUpgrade();
    setTimeout(() => {
      this.isMonthlyPlan = true;
      this.isYear = false;
      this.isYearAmount = false;
      this.isMonth = true;
      this.isMonthAmount = true;
    }, 100);
  }

  getMonthAmount(data: any, args: any, addOnAmount: any) {
    this.isMonthlyPlan = true;
    return (data.selectedPackageCode == args.detail.value && data.subscription_duration == 1 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(args.detail.value, false);
  }

  getYearAmount(data: any, args: any, addOnAmount: any) {
    this.isMonthlyPlan = false;
    return (data.selectedPackageCode == args.detail.value && data.subscription_duration == 2 && data.defaultAddOnActive == true) ? 0 : this.getPackageAmount(args.detail.value, false);
  }

  Upgrade() {
    this.GetSwitchPlanDetail(2);
  }

  Downgrade() {
    this.GetSwitchPlanDetail(1);
  }

  async GetSwitchPlanDetail(subscription_duration: any) {
    let gridData = this.grid.dataSource as any;
    let value =
    {
      atheleteDetail: gridData,
      accountId: this.accountId,
      subscriptionPackageDetails: this.packageList,
      isMonthlyPlan: this.isMonth,
      subscription_duration: subscription_duration
    }
    this.spinner.show('Please wait...');
    this.httpProvider.getSwitchPlanDetail(value).subscribe(async result => {
      this.spinner.hide();
      var args = result.body[0];
      if (args.StatusCode == 200) {
        args.isMonthlyPlan = this.isMonthlyPlan;
        args.grid = gridData;
        args.packageList = this.packageList;
        let modal = await this.modalCtrl.create({
          component: SwitchPlanModelPage,
          cssClass: 'stripe-modal',
          componentProps: args
        });
        await modal.present();
      }
    }, async (error: any) => {
      let gridData = this.grid.dataSource as any;
      this.spinner.hide();
      var errorValue = error.error[0];
      if (errorValue.StatusCode == 406) {
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: errorValue.Message,
          buttons: ['OK']
        });
        (await alert).present();
      }
      else if (errorValue.StatusCode == 404) {
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: errorValue.Message,
          buttons: ['OK']
        });
        (await alert).present();
      }
      else if (errorValue.StatusCode == 409) {
        errorValue.grid = gridData;
        errorValue.isMonthlyPlan = this.isMonthlyPlan;
        let modal = await this.modalCtrl.create({
          component: SwitchPlanCancelModelPage,
          cssClass: 'stripe-modal',
          componentProps: errorValue
        });
        await modal.present();
      }
      else if(error && error.message){
        this.toaster.showFailureToast(error.message);
        }
    });
  }

  rowSelected(args: RowSelectEventArgs) {
  }

  rowDeselected(args: RowSelectEventArgs) {
  }

  getDisableUpgrade(value:any) {
    setTimeout(function () {
      var upgrade: any;
      upgrade = document.getElementsByClassName("Upgrade");
      for (var i = 0; i < upgrade.length; i++) {
        upgrade[i].disabled = value;
      };
    }, 500);
  }

  getDisableDowngrade(value: any) {
    setTimeout(function () {
      var downgrade: any;
      downgrade = document.getElementsByClassName("Downgrade");
      for (var i = 0; i < downgrade.length; i++) {
        downgrade[i].disabled = value;
      };
    }, 1000);
  }

  getMonthlyPlan(data: any) {
    if (this.isSubscribed) {
      if (data.subscription_duration != null) {
        if (data.subscription_duration == 1) {
          this.isMonthlyPlan = true;
        }
        else {
          this.isMonthlyPlan = false;
        }
      }
    }
  }

  async showAlertForSameDuration(data: any) {
    if (this.isSubscribed) {
      if (data.subscription_duration == null) {
        var gridDataSouce = this.grid.dataSource as any;
        if (gridDataSouce.length > 0) {
          var firstData: any;
          gridDataSouce.forEach((i: any) => {
            if (i.subscription_duration != null) {
              firstData = i;
            }
          });
          if (firstData.subscription_duration == 1 && this.isYear) {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: "You are in monthly plan, so new athlete also will be join the same",
              buttons: ['OK']
            });
            (await alert).onDidDismiss().then(e => {
              this.SwitchToMonth();
            });
            (await alert).present();
          }
          else if (firstData.subscription_duration == 2 && this.isMonth) {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: "You are in yearly plan, so new athlete also will be join the same",
              buttons: ['OK']
            });
            (await alert).onDidDismiss().then(e => {
              this.SwitchToYear();
            });
            (await alert).present();
          }
        }
      }
    }
  }

  disableDownandUpgrade() {
    if (this.grid && this.grid.dataSource) {
      var gridDataSouce = this.grid.dataSource as any;
      if (gridDataSouce != null) {
        if (gridDataSouce.length > 0) {
          var firstData: any;
          gridDataSouce.forEach((i: any) => {
            if (i.subscription_duration != null) {
              firstData = i;
              if (firstData.subscription_duration != null) {
                if (firstData.subscription_duration == 1) {
                  this.getDisableDowngrade(true);
                }
                else if (firstData.subscription_duration == 2) {
                  this.getDisableUpgrade(true);
                }
              }
              else {
                if (this.isMonthlyPlan) {
                  this.getDisableDowngrade(true);
                }
                else {
                  this.getDisableUpgrade(true);
                }
              }
            }
          });
        }
        else {
          this.getDisableUpgrade(false);
          this.getDisableDowngrade(false);
        }
      }
    }
  }

  gridRefresh() {
    this.spinner.show('Please wait...');
    setTimeout(() => {
      this.grid.refresh();
    }, 500);
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }

  openPopover(event: any) {
    if (event != null && event != undefined && event != "" && event.srcElement != undefined && event.srcElement != null && event.srcElement.offsetWidth != undefined && event.srcElement.offsetWidth != null && event.srcElement.offsetWidth != 0) {
      setTimeout(() => {
        let popoverElement: any = document.getElementsByClassName('feature-package-popover');
        if(popoverElement != null && popoverElement != undefined && popoverElement.length > 0){
        popoverElement[0].style.setProperty('--width', event.srcElement.offsetWidth + 'px');
        }
      }, 50);
    }
  }
}


