import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule, NavParams } from '@ionic/angular';

import { AddAthletePageRoutingModule } from './add-athlete-routing.module';

import { AddAthletePage } from './add-athlete.page';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule, DateTimePickerModule, DateRangePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { MultiSelectModule, AutoCompleteModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { ImagecropperModule } from '../_layout/imagecropper/Imagecropper.module';
import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { HomeFooterModule } from '../home-footer/home-footer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddAthletePageRoutingModule,
    DatePickerModule,
    MultiSelectModule,
    CheckBoxModule,
    GridAllModule,
    TabModule,
    NumericTextBoxModule,
    DateTimePickerModule,
    ScheduleAllModule,
    AutoCompleteModule,
    ChartAllModule,
    DateRangePickerAllModule,
    MultiSelectAllModule,
    ImagecropperModule,
    MaskedTextBoxModule,
    HomeFooterModule
  ],
  declarations: [AddAthletePage],
  providers: [NavParams]
})
export class AddAthletePageModule { }
