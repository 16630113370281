import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { IAthlete, IBbSbDetails, IHeightWeight } from '../interfaces/account-interface';
import { PrivacyPolicyPage } from '../privacy-policy/privacy-policy.page';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { AlertService } from '../providers/alert-service.service';
import { DataSharingService } from '../providers/data-sharing.service';
import { BirthDateProvider } from '../providers/birth-date.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';
import { TermsAndConditionsPage } from '../terms-and-conditions/terms-and-conditions.page';
import { CheckBoxSelectionService, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';

let current_fs: any;
let next_fs: any;
let previous_fs;

@Component({
  selector: 'app-add-athlete',
  templateUrl: './add-athlete.page.html',
  styleUrls: ['./add-athlete.page.scss'],
  providers: [CheckBoxSelectionService]
})
export class AddAthletePage implements OnInit {
  public sportsPositionCodesFields: Object = { text: 'position_name', value: 'sports_position_code', sportscode: 'sports_focus_code' };
  public popHeight: string = '350px';
  public mode: string = "";
  public maxSelection: number = 2;
  athleteData: IAthlete = {
    athlete_id: 0, account_id: 0, first_name: '', last_name: '', birthdate: new Date(), gender: '', address1: '', address2: '', city: '',
    athlete_state_code: '', zipcode: 0, zip4: 0, area_code_phone: 0, phone: 0, email: '', parent_first_name: '', parent_last_name: '', parent_address1: '', parent_address2: '',
    parent_city: '', parent_state_code: '', parent_zipcode: 0, parent_zip4: 0, parent_email: '', parent_area_code_phone: 0, parent_phone: 0, school_team_name: '',
    graduation_year: null, school_name: '', grade_level: '', parentPhoneNumber: '', parentzipCode: '', athletePhoneNumber: '', athletezipCode: '', isparent: false, birthDate: ''
  };

  heightweightdata: IHeightWeight = { athlete_id: 0, entry_date: new Date(), height_feet: 0, height_inches: 0, weight_pounds: 0, height: '', weight: '' };

  bbSbdata: IBbSbDetails = {
    athlete_id: 0, bats: '', throws: '', league1_sports_position_code: 0, league1_sports_focus_code: 0,
    league2_sports_position_code: 0, league2_sports_focus_code: 0, league1_type: "", league1_name: "", league2_type: "", league2_name: "", league1PositionName: '', league2PositionName: '', league3PositionName: '', league4PositionName:'', league5PositionName:'', league6PositionName: "", league7PositionName: '', league8PositionName:'', league9PositionName:'', grade_level: '', affiliate_id: 0, name: '', sports_postion_name: '', positionCode: '', sports_position_club_name: '', TrainingCenter: "", league1PositionShortName: "",
    league2PositionShortName: "", league3PositionShortName: "", league4PositionShortName: "", league5PositionShortName: "", league6PositionShortName: "", league7PositionShortName: "", league8PositionShortName: "", league9PositionShortName: "", league3_sports_position_code: 0, league4_sports_position_code: 0, league5_sports_position_code: 0, league6_sports_position_code: 0, league7_sports_position_code: 0, league8_sports_position_code: 0, league9_sports_position_code: 0
  };
  isleague2typevalid: Boolean = false;
  isleague2namevalid: Boolean = false;
  maximumLength: number = 40;
  maximumSelectionLength: number;
  sportspositioncode: any;
  isParent: Boolean = false;
  submitted = false;
  careerSubmitted = false;
  profileSubmitted = false;
  isTermsChecked = false;
  profileImage: any;
  loading: any;
  isFirst: Boolean = false;
  isAlreadyExist: Boolean = false;
  isEmailValid: Boolean = false;
  errorMessage: string = "";
  isMaxWeightValid = false;
  isMinWeightValid: Boolean = false;
  enableEmail: Boolean = true;
  parentPhoneNumberStore: any;
  previewImage: any;
  imageName: string = "No file chosen";
  isImageUpdate: Boolean = false;
  accountTypeId: number = 0;
  isAlreadyLoggedIn: Boolean = false;
  accountId: number = 0;
  isPositionValid: boolean = false;
  isProfileImageChanged: boolean = false;
  isSportsFocusCodeValid: boolean = false;
  isSportsComplexValid: boolean = false;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();

  public diffForParent = this.currentYear - 5;
  public diffForAthlete = this.currentYear - 13;
  public birthyear: number = 0;
  isAthletePhoneValid: Boolean = false;
  invalidBirthDate: boolean = false;
  public parentMaxDate: Date = new Date(this.diffForParent, this.currentMonth, this.currentDay);
  public athleteMaxDate: Date = new Date(this.diffForAthlete, this.currentMonth, this.currentDay);
  footerYear: any = new Date().getFullYear();
  @ViewChild('checkbox')
  public checkbox: CheckBoxComponent | any;
  @ViewChild('weights')
  public weightObj: NumericTextBoxComponent | any;
  public phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  multiSelectClass: string;
  profileImageCopy: any;
  isShowImageCropper: boolean = false;
  imageData: any;
  isValidData: boolean = false;
  isFirstNameValid: boolean = false;
  isLastNameValid: boolean = false;
  isMobileView: boolean = false;
  webSize: boolean = false;
  selectAllText: string | any;
  @ViewChild('athleteBirthdate')
  public athleteBirthDate: DatePickerComponent | any;
  @ViewChild('graduationyear')
  public graduationYear: DatePickerComponent | any;
  @ViewChild('sports_position_codes')
  public sportsPositionCodes: MultiSelectComponent | any;
  
  constructor(public navCtrl: NavController,
    public accountService: AccountService, public httpProvider: HttpProvider,
    public modalCtrl: ModalController, private loadingCtrl: LoadingController,
    private titleService: Title, public alertCtrl: AlertController,
    public platform: Platform, private alertService: AlertService, private toaster: ToasterService,
    private spinner: SpinnerService, private router: Router, private route: ActivatedRoute,
    private dataSharingService: DataSharingService, private cdRef: ChangeDetectorRef,
    private birthDateProvider: BirthDateProvider) {
      if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
        this.isMobileView = false;
        this.webSize = true;
      } else {
        this.isMobileView = true;
      }
      if (this.webSize == true) {
        if (window.matchMedia("(max-width: 800px)").matches) {
          this.isMobileView = true;
        }
      }
      
    this.isFirst = true;
    this.multiSelectClass = "multiSelectDisabled";
    this.maximumSelectionLength = 2;
    this.sportspositioncode = [];

    this.isAlreadyLoggedIn = this.accountService.hasLoggedIn();
    if (this.isAlreadyLoggedIn === true) {
      localStorage.setItem('storedURL', window.location.href);
      var accountType = localStorage.getItem('type') ||'';
      if(accountType == '5'){
      this.GetAllLevels();
      this.GetAllStates();
      this.GetAllSports();
      this.GetAllAffiliates();
      this.GetAllSportsPositions();
      }else if(accountType == '6'){
        this.router.navigate(['profile/view'], { replaceUrl: true });
      }
    }
    else {
      this.router.navigate(['login'], { replaceUrl: true });
    }

    this.route.queryParams.subscribe((params) => {
      if ((this.router.getCurrentNavigation() as any).extras.state) {
        let data = (this.router.getCurrentNavigation() as any).extras.state;
        if (data) {
          if (data.pageName == 'profile/view') {
            this.accountId = data.accountId;
            this.previewImage = data.profileImageSource;
            this.profileImageCopy = this.previewImage;
          }
        }
      }
    });
    this.dataSharingService.setCroppedImageSource.subscribe(value => {
      if (value) {
        this.previewImage = value;
        var fileBlob = this.dataURItoBlob(value);
        var fileId = this.blobToFile(fileBlob, "my-image.png");
        if (fileId) {
          let formData: FormData = new FormData();
          formData.append("uploadFile", fileId, "modifiedCanvas.png");
          this.profileImage = formData;
        }
        this.setUploadImage();
      }
    });
    this.dataSharingService.closeImageCropper.subscribe(value => {
      if (value) {
        this.setUploadImage();
      }
    });
  }

  @ViewChild('fileInp') fileInput: ElementRef | any;
  @Input() label: string | any;
  @Output() data = new EventEmitter<FormData>();

  fileUpload(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList[0].type != "image/png" && fileList[0].type != "image/jpg" && fileList[0].type != "image/jpeg" && fileList[0].type != "image/gif") {
      this.alertService.showAlertWithOkBtn('Alert', 'Image Format is invalid');
      return;
    }
    let formdata = new FormData();
    formdata.append('profileImage', fileList[0], fileList[0].name);
    this.isProfileImageChanged = true;
    this.profileImage = formdata;
    this.imageName = fileList[0].name;
    let FileReader: new () => FileReader = ((window as any).FileReader as any).__zone_symbol__OriginalDelegate
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.previewImage = event.target.result;
      this.isImageUpdate = true;
      this.editProfileImageCopy = event.target.result;
      this.editProfileImage();
    }
    reader.readAsDataURL(event.target.files[0]);
  }
  editProfileImageCopy: any;

  editProfileImage() {
    var _this = this;

    //Convert image datauri to blob file
    var dataURItoBlob = function (dataURI: any) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;

    }

    // Convert blob to file format
    var blobToFile = function (theBlob: any, fileName: any) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    }

    // Moved image data image cropper view
    this.spinner.show('Please wait...');
    this.imageData = this.editProfileImageCopy;
    setTimeout(() => {
      this.isShowImageCropper = true;
      this.dataSharingService.isShowImageFooter.next(true);
      this.cdRef.detectChanges();
    }, 100);
  }

  RemoveFiles() {
    this.profileImage = new FormData();
    this.previewImage = this.profileImageCopy;
    this.isProfileImageChanged = false;
    this.isImageUpdate = false;
    this.fileInput.nativeElement.value = '';
    this.cdRef.detectChanges();
  }

  onClick() {
    this.fileInput.nativeElement.click();
  }

  ngOnInit() {
    this.mode = 'CheckBox';
    this.selectAllText= 'Select All';
    var isTermsChecked: any;
    isTermsChecked = document.getElementsByClassName("IsTermsChecked");
    for (var i = 0; i < isTermsChecked.length; i++) {
      isTermsChecked[i].disabled = true;
    };
    var checkAccountId = this.accountService.getAccountId();
    if (checkAccountId != null && checkAccountId != undefined) {
      this.accountId = parseInt(checkAccountId);
      this.httpProvider.GetAccountType(this.accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData != null && resultData > 0 && resultData === 5) {
            this.isParent = true;
            this.athleteData.birthdate = new Date(this.diffForParent, this.currentMonth, this.currentDay);
          }
          else if (resultData === 6) {
            this.isParent = false;
            this.athleteData.birthdate = new Date(this.diffForAthlete, this.currentMonth, this.currentDay);
          }
          else if (resultData == null) {
            this.accountService.logout();
            this.router.navigate(['login'], { replaceUrl: true });
          }
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
          this.router.navigate(['login'], { replaceUrl: true });
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });
    }
    this.titleService.setTitle('Account | iPost Sports');
    let alertController = this.alertCtrl;
    let isFirstAlert: boolean = true;
  }

  OndocumentClick(e: any) {
    if (e.srcElement != undefined && e.srcElement.className.indexOf("e-ddl") == -1) {
      let collect: any = document.getElementsByClassName("e-dropdownlist");
      for (var i = 0; i < collect.length; i++) {
        collect[i].ej2_instances[0].hidePopup();
        collect[i].ej2_instances[0].focusOut();
      }
    }
  }

  OnOpenPosition(e: any) {
    let select: any = window.getSelection();
    select.removeAllRanges();
  }

  OnchangePosition(e: any) {
    let select: any = window.getSelection();
    select.removeAllRanges();
  }

  OnSelectPosition(e: any) {
    setTimeout(function () {
      let select: any = window.getSelection();
      select.removeAllRanges();
    }, 100);
  }
  weightfocus(e: any) {
    setTimeout(() => {
      let len = this.weightObj.element.value ? this.weightObj.element.value.length : 0;
      this.weightObj.element.setSelectionRange(len, len);
    }, 1);
  };
  public genderWaterMark: string = 'Select a Gender';
  public stateWaterMark: string = 'Select a State';
  public batsWaterMark: string = 'Select a Bat';
  public throwsWaterMark: string = 'Select a Throw';
  public levelList: string[] | any;// = ['Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade','6th Grade', '7th Grade', '8th Grade', 'Freshman', 'Sophomore', 'Junior', 'Senior'];
  public levelWaterMark: string = 'Select a Level';
  public graduatingYearList: number[] = [2018, 2019, 2020, 2021, 2022];
  public graduatingYearWaterMark: string = 'Select a Graduating Year';
  public leagueList: string[] = ['City', 'Club', 'School', 'Hitting Network'];
  public leagueWatermark: string = 'Select a League';

  public heightFeetList: Object[] = [
    { Id: "2", Text: '2' },
    { Id: "3", Text: '3' },
    { Id: "4", Text: '4' },
    { Id: "5", Text: '5' },
    { Id: "6", Text: '6' },
    { Id: "7", Text: '7' }
  ];

  public heightFields: Object = { text: 'Text', value: 'Id' };
  public feetWaterMark: string = "Feet";

  public heightInchesList: Object[] = [
    { Id: "00", Text: '00' },
    { Id: "01", Text: '01' },
    { Id: "02", Text: '02' },
    { Id: "03", Text: '03' },
    { Id: "04", Text: '04' },
    { Id: "05", Text: '05' },
    { Id: "06", Text: '06' },
    { Id: "07", Text: '07' },
    { Id: "08", Text: '08' },
    { Id: "09", Text: '09' },
    { Id: "10", Text: '10' },
    { Id: "11", Text: '11' }
  ];

  public heightInchesFields: Object = { text: 'Text', value: 'Id' };
  public inchesWaterMark: string = "Inch(es)";

  public batsList: Object[] = [
    { Id: "R", Text: 'Right' },
    { Id: "L", Text: 'Left' },
    { Id: "B", Text: 'Both' }
  ];

  public batsFields: Object = { text: 'Text', value: 'Id' };

  public throwsList: Object[] = [
    { Id: "R", Text: 'Right' },
    { Id: "L", Text: 'Left' }
  ];

  public throwsFields: Object = { text: 'Text', value: 'Id' };

  public genderList: Object[] = [
    { Id: "M", Text: 'Male' },
    { Id: "F", Text: 'Female' }
  ];

  public genderFields: Object = { text: 'Text', value: 'Id' };
  public stateList: any;
  public stateFields: Object = { text: 'state_name', value: 'state_code' };
  public sportsList: any;
  public sportsFields: Object = { text: 'sports_focus_name', value: 'sports_focus_code' };
  public SportsWaterMark: string = 'Select a sport';
  public stateValue: string = "";

  public positionList: any;
  public positionListData: any;
  public positionfields: Object = { text: 'position_name', value: 'sports_position_code', sportscode: 'sports_focus_code' };
  public positionWaterMark: string = 'Select a Position';

  public trainingCenterList: any;
  public trainingfields: Object = { text: 'name', value: 'affiliate_id' };
  public trainingWaterMark: string = 'Select a Sports Complex';

  public changeHandler(): void {
    if (this.checkbox.checked == true) {
      this.isTermsChecked = true;
    }
    else {
      this.isTermsChecked = false;
    }
    var isTermsChecked: any;
    isTermsChecked = document.getElementsByClassName("IsTermsChecked");
    for (var i = 0; i < isTermsChecked.length; i++) {
      isTermsChecked[i].disabled = !this.isTermsChecked;
    };
  }

  GetAllLevels() {
    this.httpProvider.GetAllLevels().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.levelList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          this.levelList.push(resultData[i].grade.toString());
        }
      }
    }, (error: any) => {
      if (error.status == 401) {
        this.accountService.logout();
        this.router.navigate(['login'], { replaceUrl: true });
      }
      else if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  GetAllStates() {
    this.httpProvider.GetAllStates().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.stateList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          resultData[i].state_code = resultData[i].state_code.toString();
        }

        this.stateList = resultData;
        this.LoadParentDetails();
        //     setTimeout(()=>{    
        //         this.stateValue = localStorage.getItem("parentStateCode");
        //         this.athleteStateObj.value=localStorage.getItem("parentStateCode");
        //    },1000);

      }

    }, (error: any) => {
      if (error.status == 401) {
        this.accountService.logout();
        this.router.navigate(['login'], { replaceUrl: true });
      }
      else if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  GetAllSports() {
    this.httpProvider.GetAllSports().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.sportsList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          resultData[i].sports_focus_code = resultData[i].sports_focus_code.toString();
        }
        this.sportsList = resultData;
      }
    }, (error: any) => {
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  GetAllSportsPositions() {
    this.httpProvider.GetAllSportsPositions().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.positionList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          resultData[i].sports_position_code = resultData[i].sports_position_code.toString();
        }
        this.positionList = resultData;
      }
    }, (error: any) => {
      if (error.status == 401) {
        this.accountService.logout();
        this.router.navigate(['login'], { replaceUrl: true });
      }
      else if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  GetAllAffiliates() {
    this.httpProvider.GetAllAffiliates().subscribe((data: any) => {
      if (data != null && data.body != null) {
        this.trainingCenterList = [];
        var resultData = data.body;
        for (let i = 0; i < resultData.length; i++) {
          resultData[i].affiliate_id = resultData[i].affiliate_id.toString();
        }
        resultData.sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name));
        this.trainingCenterList = resultData;
      }
    }, (error: any) => {
      if (error.status == 401) {
        this.accountService.logout();
        this.router.navigate(['login'], { replaceUrl: true });
      } else if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
    });
  }

  public onSportsChange(args: any) {
    var filterData = this.positionList.filter((a: any) => a.sports_focus_code == args.detail.value);
    this.positionListData = filterData;
    if(args.detail.value != null && args.detail.value != "" && args.detail.value != undefined){
      this.isSportsFocusCodeValid = true;
    }else{
      this.isSportsFocusCodeValid = false;
    }
  }

  public onSportsComplexChange(args: any){
    if(args.detail.value != null && args.detail.value != "" && args.detail.value != undefined){
      this.isSportsComplexValid= true;
    }else{
      this.isSportsComplexValid= false;
    }
  }

  onMemberAccountCareerClick(event: any, form: NgForm) {
    this.careerSubmitted = true;
    this.birthyear = this.birthyear != null ? this.athleteData.birthdate.getFullYear() : 0;
    if (this.parentPhoneNumberStore != this.athleteData.athletePhoneNumber) {
      if (!((this.athleteData.athletePhoneNumber.length == 10) && (this.athleteData.athletePhoneNumber.charAt(0) != "0") && form.controls.athletePhoneNumber.valid)) {
        this.isAthletePhoneValid = true;
        var athletePhoneNumber = document.getElementById('athletePhoneNumber') as any;
        athletePhoneNumber.scrollIntoView({ behavior: "smooth" });
      }
      else {
        this.isAthletePhoneValid = false;
      }
    }
    else {
      this.isAthletePhoneValid = false;
    }
    if (!this.isAthletePhoneValid && !this.isEmailValid) {
      this.invalidBirthDate = this.birthDateProvider.ValidateBirthDate(this.athleteData.birthdate, this.isParent);
      if (!this.invalidBirthDate) {
        this.athleteData.birthDate = this.athleteData.birthdate.toDateString();
        if (this.isTermsChecked && form.form.controls.first_name.status == "VALID" && form.form.controls.last_name.status == "VALID" && form.form.controls.gender.status == "VALID" && form.form.controls.address1.status == "VALID" && form.form.controls.address2.status == "VALID" &&
          form.form.controls.city.status == "VALID" && form.form.controls.athletezipCode.status == "VALID" && form.form.controls.birthdate.status == "VALID" &&
          form.form.controls.athletePhoneNumber.status == "VALID" && (form.form.controls.email_address.status == "DISABLED" || form.form.controls.email_address.status == "VALID") && (this.isFirstNameValid == false && this.isLastNameValid == false)) {
          let target = event.currentTarget;
          let pElement = target.parentElement.parentElement;
          current_fs = pElement.parentElement;
          next_fs = current_fs.nextElementSibling;
          this.isAlreadyExist = false;
          next_fs.style.display = 'block';
          current_fs.style.display = 'none';
          var athleteNewPage = document.getElementById('scrollToTopOnMobile') as any;
          athleteNewPage.scrollIntoView({ behavior: "smooth" });
          this.bbSbdata.name = this.athleteData.first_name + " " + this.athleteData.last_name;
        }
        else {
          if (!form.controls.first_name.valid) {
            var first_name = (document.getElementById('athlete-firstname') as any);
            first_name.scrollIntoView({ behavior: "smooth" });
          }
          else if (this.isFirstNameValid == true) {
            var first_name = (document.getElementById('athlete-firstname') as any);
            first_name.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.last_name.valid) {
            var last_name = (document.getElementById('athlete-lastname') as any);
            last_name.scrollIntoView({ behavior: "smooth" });
          }
          else if (this.isLastNameValid == true) {
            var last_name = (document.getElementById('athlete-lastname') as any);
            last_name.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.address1.valid) {
            var address1 = (document.getElementById('athlete-address1') as any);
            address1.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.address2.valid) {
            var address1 = (document.getElementById('athlete-address2') as any);
            address1.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.birthdate.valid) {
            var athleteBirthDate = (document.getElementById('athleteBirthdate') as any);
            athleteBirthDate.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.athletezipCode.valid) {
            var athletezipCodeValue = (document.getElementById('athletezipCode') as any);
            athletezipCodeValue.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.gender.valid) {
            var gender = (document.getElementById('gender') as any);
            gender.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.athletePhoneNumber.valid) {
            var athletePhoneNumberValue = (document.getElementById('athletePhoneNumber') as any);
            athletePhoneNumberValue.scrollIntoView({ behavior: "smooth" });
          }
          else if (!form.controls.email.valid) {
            var email = (document.getElementById('email') as any);
            email.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
      else {
        var last_name = (document.getElementById('last_name') as any);
        last_name.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  onMemberAccountProfileClick(event: any, form: NgForm) {
    this.profileSubmitted = true;
    let weight = + this.heightweightdata.weight;
    if (weight < 50 && weight != 0) {
      this.isMinWeightValid = true;
      this.isMaxWeightValid = false;
      var weightpounds = (document.getElementById('weight') as any);
      weightpounds.scrollIntoView({ behavior: "smooth" });
    }
    else if (weight > 400  && weight != 0) {
      this.isMinWeightValid = false;
      this.isMaxWeightValid = true;
      var weightpoundsData = (document.getElementById('weight') as any);
      weightpoundsData.scrollIntoView({ behavior: "smooth" });
    }
   
    else {
      this.isPositionValid = false;
      this.isMinWeightValid = false;
      this.isMaxWeightValid = false;
      if (form.valid) {
        this.spinner.show('Please wait...');
        this.accountId = parseInt(this.accountService.getAccountId());
        this.httpProvider.GetAccountType(this.accountId).subscribe((data: any) => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData != null && resultData > 0 && resultData === 5) {
              this.isParent = true;
            }
            else if (resultData === 6) {
              this.isParent = false;
            }
          }
        }, (error: any) => {
          if (error.status == 401) {
            this.accountService.logout();
            this.router.navigate(['login'], { replaceUrl: true });
          }
          else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          }
        });
        let athleteZip = this.athleteData.athletezipCode.toString().split("-")[0];
        let athleteZip4 = this.athleteData.athletezipCode.toString().split("-")[1];
        let athletePhone = this.athleteData.athletePhoneNumber.substring(3);
        let athletePhoneCode = this.athleteData.athletePhoneNumber.substring(0, 3);
        let heightFeet = this.heightweightdata.height_feet;
        let heightInches = this.heightweightdata.height_inches;

        if(this.athleteData.graduation_year != null && this.athleteData.graduation_year != undefined ){
          this.athleteData.graduation_year = new Date(this.athleteData.graduation_year).getFullYear();
        }else{
           this.athleteData.graduation_year = null;
        }
        this.athleteData.zipcode = + athleteZip;
        this.athleteData.zip4 = + athleteZip4;;
        this.athleteData.phone = + athletePhone;
        this.athleteData.area_code_phone = + athletePhoneCode;
        this.heightweightdata.height_feet = + heightFeet;
        if (heightInches != null) {
          this.heightweightdata.height_inches = + heightInches;
        }
        else {
          this.heightweightdata.height_inches = 0;
        }
        this.heightweightdata.weight_pounds = + this.heightweightdata.weight.toString();
        this.bbSbdata.league1_sports_position_code = this.sportspositioncode[0] != null ? this.sportspositioncode[0] : null;
        this.bbSbdata.league2_sports_position_code = this.sportspositioncode[1] != null ? this.sportspositioncode[1] : null;
        this.bbSbdata.league3_sports_position_code = this.sportspositioncode[2] != null ? this.sportspositioncode[2] : null;
        this.bbSbdata.league4_sports_position_code = this.sportspositioncode[3] != null ? this.sportspositioncode[3] : null;
        this.bbSbdata.league5_sports_position_code = this.sportspositioncode[4] != null ? this.sportspositioncode[4] : null;
        this.bbSbdata.league6_sports_position_code = this.sportspositioncode[5] != null ? this.sportspositioncode[5] : null;
        this.bbSbdata.league7_sports_position_code = this.sportspositioncode[6] != null ? this.sportspositioncode[6] : null;
        this.bbSbdata.league8_sports_position_code = this.sportspositioncode[7] != null ? this.sportspositioncode[7] : null;
        this.bbSbdata.league9_sports_position_code = this.sportspositioncode[8] != null ? this.sportspositioncode[8] : null;
        this.bbSbdata.league2_sports_focus_code = this.bbSbdata.league1_sports_focus_code;
        // if(this.bbSbdata.league2_type != null || this.bbSbdata.league2_name != null){
        //     this.bbSbdata.league2_type = null;
        //     this.bbSbdata.league2_name = null;
        // }
        this.athleteData.account_id = this.accountId;
        this.athleteData.isparent = this.isParent;

        let target = event.currentTarget;
        let pElement = target.parentElement.parentElement;
        current_fs = pElement.parentElement;
        next_fs = current_fs.nextElementSibling;
        let sucsessFieldset = document.getElementById('sucsessFieldset') as HTMLInputElement;
        this.athleteData.birthDate = this.athleteData.birthdate.toDateString();
        this.httpProvider.saveMemberDetails(this.athleteData, this.heightweightdata, this.bbSbdata).subscribe((data: any) => {
          if (data != null && data.body != null) {
            var resultData = data.body;
            if (resultData != null && resultData.IsSuccess == true) {
              this.httpProvider.SaveProfileImage(this.profileImage, resultData.AthleteId.toString(), resultData.AccountId.toString(), resultData.SportsFocusCode.toString()).subscribe((data: any) => {
              }, (error: any) => {
                if (error && error.message) {
                  this.toaster.showFailureToast(error.message);
                }
              });
              this.httpProvider.GetSubscribed(this.accountId).subscribe(result => {
                localStorage.setItem("hideMenuPage", result.body)
              }, (error: any) => {
                if (error && error.message) {
                  this.toaster.showFailureToast(error.message);
                }
              });
              this.spinner.hide();
              next_fs.style.display = 'block';
              current_fs.style.display = 'none';
              sucsessFieldset.style.display = 'block';
              localStorage.setItem('ispackDisable', "false");
              localStorage.setItem('accountSettingsPage', "false");
            }
            else {
              if (resultData != null && resultData.IsSuccess == false && resultData.Message != null && resultData.Message != "") {
                this.isAlreadyExist = true;
                this.errorMessage = resultData.Message;
                this.spinner.hide();
              }
              else {
                alert(resultData.Message);
              }
            }
          }
        }, (error: any) => {
          if (error.status == 401) {
            this.accountService.logout();
            this.router.navigate(['login'], { replaceUrl: true });
          }
          else if (error && error.message) {
            this.toaster.showFailureToast(error.message);
          }
        });
      }
      else {
        if (!form.controls.sports_focus_code.valid) {
          var sports_focus_code = (document.getElementById('sports_focus_code') as any);
          sports_focus_code.scrollIntoView({ behavior: "smooth" });
        }
        // else if (!form.controls.sports_position_code.valid) {
        //     var sports_position_code = document.getElementById('sports_position_code');
        //     sports_position_code.scrollIntoView({ behavior: "smooth" });
        // }
        else if (!form.controls.league1_type.valid) {
          var league1_type = (document.getElementById('league1_type') as any);
          league1_type.scrollIntoView({ behavior: "smooth" });
        }
        else if (!form.controls.team_name.valid) {
          var team_name = (document.getElementById('league1-name') as any);
          team_name.scrollIntoView({ behavior: "smooth" });
        }
        else if (!form.controls.affiliate_id.valid) {
          var trainingcenter = (document.getElementById('trainingcenter') as any);
          trainingcenter.scrollIntoView({ behavior: "smooth" });
        }
        else if (!form.controls.school_name.valid) {
          var club_name = (document.getElementById('school-name') as any);
          club_name.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  onMemberAccountProfileBackClick(event: any) {
    let target = event.currentTarget;
    let pElement = target.parentElement.parentElement;
    current_fs = pElement.parentElement;
    previous_fs = current_fs.previousElementSibling;
    previous_fs.style.display = 'block';
    current_fs.style.display = 'none';
  }

  clickselectPackage() {
    let navigationExtras: NavigationExtras = { state: { data: this.accountId } };
    this.router.navigate(['package'], navigationExtras);
  }

  clickselectAnotherAthlete() {
    this.router.navigate(['features'], { replaceUrl: true });
    setTimeout(() => {
      this.router.navigate(['addathlete'], { replaceUrl: true });
    }, 100);
  }

  async clickTermsandconditions() {
    let modal = await this.modalCtrl.create({ component: TermsAndConditionsPage });
    modal.present();
  }

  async clickPrivacyPolicy() {
    let modal = await this.modalCtrl.create({ component: PrivacyPolicyPage });
    modal.present();
  }

  calculateBirthdate(args: any) {
    document.getElementById('athleteBirthdate') as HTMLInputElement;
    if (args.value != null && args.value != undefined) {
      let dob: any = new Date(args.value);
      let today: any = new Date();
      let age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
      if (age > 13) {
        this.enableEmail = false;
      }
      else {
        this.enableEmail = true;
      }
      this.invalidBirthDate = this.birthDateProvider.ValidateBirthDate(dob, this.isParent);
    }
  }

  restrictNumeric(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  LoadParentDetails() {
    var checkAccountId = this.accountService.getAccountId();
    if (checkAccountId != null && checkAccountId != undefined) {
      this.spinner.show('Please wait...');
      //Load AthleteDetails
      this.accountId = parseInt(this.accountService.getAccountId());
      this.httpProvider.GetAccountType(this.accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData != null && resultData > 0 && resultData === 5) {
            this.isParent = true;
          }
          else if (resultData === 6) {
            this.isParent = false;
          }
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
          this.router.navigate(['login'], { replaceUrl: true });
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });
      this.httpProvider.GetAthleteOrParentDetails(this.accountId).subscribe((data: any) => {
        if (data != null && data.body != null) {
          var resultData = data.body;
          if (resultData != null) {
            this.athleteData.address1 = resultData.parent_address1;
            this.athleteData.address2 = resultData.parent_address2;
            this.athleteData.city = resultData.parent_city;
            this.athleteData.athlete_state_code = resultData.parent_state_code != null ? resultData.parent_state_code.toString() : null;
            this.stateValue = this.isParent ? resultData.parent_state_code.toString() : resultData.athlete_state_code;
            this.athleteData.athlete_state_code = this.isParent ? resultData.parent_state_code.toString() : resultData.athlete_state_code;
            this.athleteData.email = resultData.parent_email;
            this.athleteData.athletePhoneNumber = "(" + resultData.parent_area_code_phone + ") " + resultData.parent_phone;
            this.parentPhoneNumberStore = "(" + resultData.parent_area_code_phone + ") " + resultData.parent_phone;
            if (resultData.parent_zip4 > 0) {
              this.athleteData.athletezipCode = resultData.parent_zipcode + "-" + resultData.parent_zip4;
            }
            else {
              this.athleteData.athletezipCode = resultData.parent_zipcode;
            }
          }
          else {
            this.router.navigate(['type']);
            window.location.hash = '/type';
          }
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.accountService.logout();
          this.router.navigate(['login'], { replaceUrl: true });
        }
        else if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });
      this.spinner.hide();
    }
  }

  backButtonAction() {
    window.history.back();
  }

  onItemSelect(item: any) {
    console.log(item);
  }


  dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  // Convert blob to file format
  blobToFile(theBlob: any, fileName: any) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  validateNumber(event: any) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  };

  Dismiss() {
    this.router.navigate(['package'], { replaceUrl: true });
  }

  setUploadImage() {
    this.isShowImageCropper = false;
    this.cdRef.detectChanges();
    setTimeout(() => {
      var athleteInfoDiv = document.getElementById('AthleteInfoDiv');
      if (athleteInfoDiv != null) {
        athleteInfoDiv.style.display = 'none';
      }
      var scrollToTopOnMobile = document.getElementById('scrollToTopOnMobile');
      if (scrollToTopOnMobile != null) {
        scrollToTopOnMobile.style.display = 'block';
      }
      var sports_focus_code = document.getElementById('sports_focus_code');
      if (sports_focus_code != null) {
        sports_focus_code.scrollIntoView({ behavior: "smooth" });
      }
      this.cdRef.detectChanges();
    }, 50);
  }

  onEmailChange(event: any) {
    this.isEmailValid = this.accountService.emailValidationFunction(event);
  }

  onFirstNameChange(event: any) {
    this.isFirstNameValid = this.accountService.validateEnteredData(event);
  }

  onLastNameChange(event: any) {
    this.isLastNameValid = this.accountService.validateEnteredData(event);
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    (this.athleteBirthDate as any).hide();
    (this.graduationYear as any).hide();
    (this.sportsPositionCodes as any).hidePopup();
  }
}
