import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebApiService {

  constructor(private httpClient: HttpClient) {
  }

  // Get call method
  // Param 1 : authToken
  // Param 2 : url
  get(authToken: any, url: string): Observable<any> {      
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authToken,
          'Cache-Control' : 'no-cache',
          'Pragma' : 'no-cache'
        }),
        observe: "response" as 'body'
      };
      
      return this.httpClient.get(
          url,
          httpOptions
          )
          .pipe(
              map((response: any) => this.ReturnResponseData(response)),
              catchError(this.handleError)
          );     
  }

  // Post call method
  // Param 1 : authToken
  // Param 2 : url
  // Param 3 : model
  post(authToken: any, url: string, model: any): Observable<any> {      
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authToken,
        }),
        observe: "response" as 'body'
      };

      return this.httpClient.post(
          url,
          model,
          httpOptions)
          .pipe(
              map((response: any) => this.ReturnResponseData(response)),
              catchError(this.handleError)
          );
  }

  // Post call method without contenttype
  // Param 1 : authToken
  // Param 2 : url
  // Param 3 : model
  // Param 4 : athleteId
  // Param 5 : deletedFiles
  postWithSaveFiles(authToken: any, url: string, model: any, athleteId: any, deletedFiles: any): Observable<any> {    
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authToken,
          'athleteId': athleteId,
          'deletedFiles': deletedFiles
        }),
        observe: "response" as 'body'
      };
  
      return this.httpClient.post(
          url,
          model,
          httpOptions)
          .pipe(
              map((response: any) => this.ReturnResponseData(response)),
              catchError(this.handleError)
          );
  }

  // Post call method without contenttype
  // Param 1 : authToken
  // Param 2 : url
  // Param 3 : model
  // Param 4 : athleteId
  // Param 5 : accountId
  // Param 6 : sportsFocusCode
  postWithSaveImage(authToken: any, url: string, model: any, athleteId: any,  accountId: any, sportsFocusCode: any): Observable<any> {    
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': authToken,
          'athleteId': athleteId,
          'accountId': accountId,
          'sportsFocusCode': sportsFocusCode
        }),
        observe: "response" as 'body'
      };
  
      return this.httpClient.post(
          url,
          model,
          httpOptions)
          .pipe(
              map((response: any) => this.ReturnResponseData(response)),
              catchError(this.handleError)
          );
  }

  // Post call method without contenttype
  // Param 1 : authToken
  // Param 2 : url
  // Param 3 : model
  // Param 4 : athleteId
  // Param 5 : profileImageUrl
  postWithUpdateProfileImage(authToken: any, url: string, model: any, athleteId: any,  profileImageUrl: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({          
        'athleteId': athleteId,
        'ProfileImageUrl' : profileImageUrl,
        'Authorization': authToken,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      }),
      observe: "response" as 'body'
    };
 
      return this.httpClient.post(
          url,
          model,
          httpOptions)
          .pipe(
              map((response: any) => this.ReturnResponseData(response)),
              catchError(this.handleError)
          );
  }

  private ReturnResponseData(response: any) {
      return response;
  }

  private handleError(error: any) {     
      return throwError(error);
  }

  getWithResetPasswordKey(authToken: any, url: string, resetPasswordKey: any, applicationType: string): Observable<any> { 
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': authToken,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'resetPasswordKey': resetPasswordKey,
          'applicationId':applicationType
        }),
        observe: "response" as 'body'
      };
   
      return this.httpClient.get(
        url,
        httpOptions
      )
        .pipe(
          map((response: any) => this.ReturnResponseData(response)),
          catchError(this.handleError)
        );
  }
}