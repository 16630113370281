import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DataSharingService } from '../../providers/data-sharing.service';
import { Platform } from '@ionic/angular';
import { HttpProvider } from 'src/app/providers/http-provider.service';
import { AccountService } from 'src/app/providers/account-service.service';
import { AlertService } from 'src/app/providers/alert-service.service';
import { ToasterService } from 'src/app/providers/toaster-service.service';

@Component({
  selector: 'app-homelayout',
  templateUrl: './homelayout.component.html',
  styleUrls: ['./homelayout.component.scss'],
})
export class HomelayoutComponent implements OnInit {

  headerTitle: string = "";
  public href: string = "";
  backButtonName : string = "Back";
  userName: any;
  isMobile: boolean = false;
  hidepage: any;
  constructor(private router: Router, private route: ActivatedRoute, private httpProvider: HttpProvider, private dataSharingService: DataSharingService,
    private toaster: ToasterService, private platform: Platform, private alertService: AlertService, private accountService: AccountService) {
    this.userName = localStorage.getItem('username');
    let id: any = this.accountService.getAccountId();
    if (id != null) {
      this.httpProvider.GetSubscribed(id).subscribe(result => {
        localStorage.setItem("hideMenuPage", result.body);
        this.hidepage = localStorage.getItem('hideMenuPage') != null ? JSON.parse(localStorage.getItem('hideMenuPage') || '') : "";   
        this.dataSharingService.isSubscribed.next(this.hidepage); 
      },
      async (error: any) => {
        if (error && error.message) {
          this.toaster.showFailureToast(error.message);
        }
      });           
    }
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }

    this.setBackButton();
  }

  ngOnInit() {
    this.href = this.router.url;
  }

  onHomeIconClick() {
    this.router.navigate(['/profile/view'], { replaceUrl: true });
  }

  ShowInfo(title:any) {
    let message = '<div><b>START RECORDING</b>' + ' will open your mobile video camera to provide real - time video capture.</div><br/>' +
      '<div><b>UPLOAD FROM GALLERY</b> allows selection of already recorded media for upload.</div>';
    this.alertService.showAlertWithCloseBtn(title, message);
  }

  ShowCategoryInfo(title:any) {
    let message = '<div>Select the category for your video and check the box to play at your Sports Complex.</div>';
    this.alertService.showAlertWithCloseBtn(title, message);
  }

  setBackButton(){
    let url = window.location.href;
    if (url != undefined && url.split('/').length == 4) {
      let title = url.split('/')[3];
      if (title == "player") {
        this.backButtonName = "Player";
      }
      else if (title == "image-cropper") {
        this.backButtonName = "Image Cropper";
      }
      else if (title == "title") {
        this.backButtonName = "Select Title";
      }
      else if (title == "edit-screen-one") {
        this.backButtonName = "Select Category";
      }
    }
  }

  openMenu(){
    const toolbar = document.querySelector('ion-toolbar') as any;
    const shadowRoot = toolbar.shadowRoot || toolbar.attachShadow({ mode: 'open' });
    const toolbarContainer = shadowRoot.querySelector('.toolbar-container');
    toolbarContainer.style.setProperty('box-shadow', 'none'); 
    toolbarContainer.style.border = 'none';
    toolbarContainer.style.padding = '0px';
    toolbarContainer.style.background = 'white';
  }
}
