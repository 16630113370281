import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-switch-plan-cancel-model',
  templateUrl: './switch-plan-cancel-model.page.html',
  styleUrls: ['./switch-plan-cancel-model.page.scss'],
})
export class SwitchPlanCancelModelPage implements OnInit {

  Message: any;
  grid: any;
  atheleteId: any;
  accountId: any;
  NewBraintreeSubscriptionId: any;
  ExistingBraintreeSubscriptionId: any;
  isMonthlyPlan: any;
  sum: number=0;
  packageList: any;
  constructor(public navCtrl: NavController,  public httpProvider: HttpProvider,
    public modalCtrl: ModalController,private loadingCtrl: LoadingController,  private toaster : ToasterService,
    private alertCtrl: AlertController, private navParams : NavParams, private spinner : SpinnerService) {
      if (navParams != null) {
        this.Message = navParams.data.Message;      
        this.NewBraintreeSubscriptionId = navParams.data.NewBraintreeSubscriptionId;
        this.isMonthlyPlan = navParams.data.isMonthlyPlan;
        this.grid = navParams.data.grid;
      }
  }

  ngOnInit(): void {
  }

  cancelSwitchPlan(){
    this.modalCtrl.dismiss();
  }

  async proceedSwitchPlan(){
    this.modalCtrl.dismiss();
    let gridData = this.grid;
    this.spinner.show('Please wait...');
    let data = {
      NewBraintreeSubscriptionId : this.NewBraintreeSubscriptionId,           
      accountId: gridData[0].accountId
    }
    this.httpProvider.braintreeCancelSwitchPlan(data).subscribe(async data => {
      if (data.ok = true && data.body == "success" || data.body == "trial") {
        this.spinner.hide();          
        let plan = this.isMonthlyPlan == true ? "monthly" : "yearly";            
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: "You have successfully cancelled the " + plan + " subscription",
          buttons: ['OK']
        });
        (await alert).present();
      }
      else {
       this.spinner.hide();
        let alert = this.alertCtrl.create({
          header: 'Alert',
          message: 'Sorry, your payment is failed. Please try again later.',
          buttons: ['OK']
        });
        (await alert).present();
      }
        
    }, async (error: any) => {
      if (error && error.message) {
        this.toaster.showFailureToast(error.message);
      }
      else {
        let alert = this.alertCtrl.create({
          header: 'Message',
          message: error,
          buttons: ['OK']
        });
        (await alert).present();
      }
    });
  }
  
  //Close Icon click
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
