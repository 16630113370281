import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss'],
})
export class HomeFooterComponent implements OnInit {

  isKeyboardOpen:boolean = false;
  footerYear: any = new Date().getFullYear();

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.resetFooterOnKeyboardOpen();
  }

  resetFooterOnKeyboardOpen(){
    window.addEventListener('keyboardWillShow', (e) => {
      this.isKeyboardOpen=true;
      setTimeout(() => {
        this.ref.detectChanges()
      }, 100);
    });
    
    window.addEventListener('keyboardWillHide', () => {
      this.isKeyboardOpen=false;
      setTimeout(() => {
        this.ref.detectChanges()
      }, 100);
    });
  }

}
