import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { IbraintreeTransaction } from '../interfaces/account-interface';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-cancel-subscription-model',
  templateUrl: './cancel-subscription-model.page.html',
  styleUrls: ['./cancel-subscription-model.page.scss'],
})
export class CancelSubscriptionModelPage implements OnInit {
  paymentMode: string = "";
  athleteId: number = 0;
  clicked: any;
  clickedType: any;
  paymentModeType: any;
  public gridDataStore: Object[] | any;
  braintreeTransaction: IbraintreeTransaction = {
    cash: '',
    token: '',
    atheleteId: '',
    packageDescription: '',
    accountId: '',
    user: '',
    packageCode: '',
    isAddOnActive: false,
    athleteOldPackageCode: '',
    effectiveDate: null
  }
  isMonthlyPlan: any;

  constructor(public navCtrl: NavController, public httpProvider: HttpProvider,
    public modalCtrl: ModalController, private loadingCtrl: LoadingController,
    private alertCtrl: AlertController, public navParams: NavParams, private router : Router,
    private spinner : SpinnerService,  private toaster : ToasterService) {
    if (navParams != null) {
      this.braintreeTransaction.packageCode = navParams.data.packageCode;
      this.braintreeTransaction.packageDescription = navParams.data.packageDescription;
      this.braintreeTransaction.atheleteId = navParams.data.atheleteId;
      this.braintreeTransaction.user = navParams.data.user;
      this.isMonthlyPlan = navParams.data.isMonthlyPlan;
    }
  }

  ngOnInit(): void {
    this.gridDataStore = localStorage.getItem('gridDataStore') != null ? JSON.parse(localStorage.getItem('gridDataStore') || '') : "";
    localStorage.setItem('isSwitchpaymentMode', "false");
  }

  async proceedPayment() {
    this.modalCtrl.dismiss();
    this.spinner.show('Please wait...');
    if (this.braintreeTransaction.user != "parent") {
      this.httpProvider.braintreeCancelTransaction(this.braintreeTransaction).subscribe(async data => {
        if (data.ok = true && data.body == "success") {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Message',
            message: '"' + this.braintreeTransaction.packageDescription + ' has been successfully suspended."',
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(e => { 
            let navigationExtras: NavigationExtras = { state: { data: true } };
            this.router.navigate(['profile/view'], {replaceUrl: true});
          });
        }
        else {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: 'Something went wrong. Please try again later',
            buttons: ['OK']
          });
          (await alert).present();
        }
      },
        async (error: any) => {
          this.spinner.hide();
          if (error.status == 401) {
          }
          else if (error && error.message && error.status != 417) {
            this.toaster.showFailureToast(error.message);
          }
          else {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: 'Something went wrong. Please try again later.',
              buttons: ['OK']
            });
            (await alert).present();
          }
        })
    }
    else {
      let value =
      {
        atheleteDetail: this.gridDataStore,
        accountId: localStorage.getItem('accountId') || '',
        user: this.braintreeTransaction.user,
        subscriptionPackageDetails: localStorage.getItem('packageListStore') != null ? JSON.parse(localStorage.getItem('packageListStore') || '') : "",
        isMonthlyPlan: this.isMonthlyPlan
      }
      this.httpProvider.braintreeParentCancelTransaction(value).subscribe(async data => {
        if (data.ok = true && data.body == "success") {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Message',
            message: 'Selected athlete(s) subscription has been successfully suspended.',
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(e => { 
            let navigationExtras: NavigationExtras = { state: { data: true } };
            this.router.navigate(['profile/view'], {replaceUrl: true});
          });
        }
        else {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: 'Something went wrong. Please try again later',
            buttons: ['OK']
          });
          (await alert).present();
        }
      },
        async (error: any) => {
          this.spinner.hide();
          if (error.status == 401) {
          }
          else if (error && error.message && error.status != 417) {
            this.toaster.showFailureToast(error.message);
          }
          else {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: 'Something went wrong. Please try again later.',
              buttons: ['OK']
            });
            (await alert).present();
          }
        })
    }
  }

  CancelPayment() {
    this.modalCtrl.dismiss();
  }

  //Close Icon click
  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }
}