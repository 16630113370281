import { Component, EventEmitter, OnInit } from '@angular/core';

import { NavController, ModalController, NavParams, AlertController, ToastController,  } from '@ionic/angular';

import { NavigationExtras, Router } from '@angular/router';
import { IAthleteVideoDetails } from '../interfaces/account-interface';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { DataSharingService } from '../providers/data-sharing.service';

@Component({
  selector: 'app-title-model',
  templateUrl: './title-model.page.html',
  styleUrls: ["./title-model.page.scss"]
})
export class TitleModelPage implements OnInit {
  loading: any;
  header_data: any;
  isAlreadyLoggedIn: Boolean = false;
  public athleteWaterMark: string = "Select Athlete";
  athleteList: any;
  ContainersHeight: any;
  contentTop: any;
  screenType: any;
  titleText: any;
  videoCategory: any;
  ipostTV: any;
  videoThumbnail: any;
  videoFile: any;
  rawFileName: any;
  transcodedFileName: any;
  isEditTitle: any;
  isUnSyncedFile: any;
  video_id: any;
  localFilePath: any;
  titleTextCopy: any;
  athlete_id: any;
  fileSize: any;
  modificationDate: any;
  athleteVideoDetails: IAthleteVideoDetails = {
    id: 0,
    athlete_id: 0,
    video_id: 0,
    raw_file_name: '',
    transcoded_file_name: '',
    category_code: '',
    ipost_tv: '',
    video_title: '',
    localPath: '',
    account_id: 0,
    modificationDate: null,
    created_date: null
  }
  constructor(public navCtrl: NavController, public accountService: AccountService,
    public httpProvider: HttpProvider, public router: Router,
    public modalCtrl: ModalController, public navParams: NavParams, private spinner: SpinnerService,
    public alertCtrl: AlertController, public toastCtrl: ToastController, public dataSharing: DataSharingService) {
    if (navParams != null) {
      this.athlete_id = navParams.data.athlete_id != undefined ? navParams.data.athlete_id : null;
      this.videoCategory = navParams.data.category != undefined ? navParams.data.category : null;
      this.ipostTV = navParams.data.ipostTV != undefined ? navParams.data.ipostTV : null;
      this.videoFile = navParams.data.videoFile != undefined ? navParams.data.videoFile : null;
      this.titleText = navParams.data.videoTitle != undefined ? navParams.data.videoTitle : null;
      this.titleTextCopy = navParams.data.videoTitle != undefined ? navParams.data.videoTitle : null;
      this.rawFileName = navParams.data.rawFileName != undefined ? navParams.data.rawFileName : null;
      this.transcodedFileName = navParams.data.transcodedFileName != undefined ? navParams.data.transcodedFileName : null;
      let isEditable = navParams.data.isEdit != undefined ? navParams.data.isEdit : null;
      this.isEditTitle = isEditable != null ? true : false;
      this.video_id = navParams.data.videoId != undefined ? navParams.data.videoId : null;
      this.localFilePath = navParams.data.localPath != undefined ? navParams.data.localPath : null;
      this.fileSize = navParams.data.fileSize != undefined ? navParams.data.fileSize : null;
      this.modificationDate = navParams.data.modification_date != undefined ? navParams.data.modification_date : null;
    }
  }

  ngOnInit(): void {
  }

  saveUpload() {
    this.spinner.show("Please wait...");
    let accountId = this.accountService.getAccountId();
    this.athleteVideoDetails.athlete_id = this.athlete_id;
    this.athleteVideoDetails.category_code = this.videoCategory;
    this.athleteVideoDetails.ipost_tv = this.ipostTV != null ? this.ipostTV : "N";
    this.athleteVideoDetails.video_title = this.titleText;
    this.athleteVideoDetails.localPath = this.localFilePath;
    this.athleteVideoDetails.account_id = + accountId;
    this.athleteVideoDetails.modificationDate = this.modificationDate;
    this.httpProvider.GetUploadedFilesSize(this.athlete_id).subscribe(async (data: any) => {
      if (data != null && data.body != null) {
        var resultData = data.body;
        if (await this.canUploadVideo(resultData[0], resultData[1], this.fileSize)) {
          this.saveVideo(this.athleteVideoDetails);
        }
        else {
          this.spinner.hide();
        }
      }
    });
  }

  saveVideo(athleteVideoDetails: IAthleteVideoDetails) {
    // this.httpProvider.SaveAthleteVideo(this.athleteVideoDetails,this.videoFile).subscribe((data: any) => {
    //   let nav = this.app.getActiveNav();
    //   setTimeout(() => {
    //     this.modalCtrl.dismiss();
    //     this.app.getRootNav().push(ViewProfilePage);
    //     window.location.hash = "/profile/view";
    //     this.events.publish('getHighlightUploadVideos', "user", Date.now());
    //     this.spinner.hide();
    //     //  window.location.hash = "/mobile/home";
    //   }, 300);
    // }, (error : any) => {
    //   this.spinner.hide();
    //   this.modalCtrl.dismiss();
    //   alert("Something went wrong");
    //   console.log(error);
    // });
  }

  async canUploadVideo(fileSize: any, packageCode: any, videoSize: any): Promise<boolean> {
    fileSize = fileSize + videoSize;
    switch (packageCode) {
      case 4:
        if (fileSize > 300) {
          let fileSizeAlert = this.alertCtrl.create({
            message: 'Upload Failed. Your upload limit has been reached. Please upgrade your package.',
            buttons: ['Ok']
          });
          (await fileSizeAlert).present();
          return false;
        }
        else
          return true;
      case 5:
        if (fileSize > 5000) {
          let fileSizeAlert = this.alertCtrl.create({
            message: 'Upload Failed. Your upload limit has been reached. Please contact our support.',
            buttons: ['Ok']
          });
          (await fileSizeAlert).present();
          return false;
        }
        else
          return true;
      case 6:
        if (fileSize > 50000) {
          let fileSizeAlert = this.alertCtrl.create({
            message: 'Upload Failed. Your upload limit has been reached. Please contact our support.',
            buttons: ['Ok']
          });
          (await fileSizeAlert).present();
          return false;
        }
        else
          return true;
    }
    return false;
  }

  updateTitle() {
    this.spinner.show("Updating Title...");
    this.athleteVideoDetails.athlete_id = this.athlete_id;
    this.athleteVideoDetails.category_code = this.videoCategory;
    this.athleteVideoDetails.ipost_tv = this.ipostTV != null ? this.ipostTV : "N";
    this.athleteVideoDetails.video_title = this.titleText;
    this.athleteVideoDetails.raw_file_name = this.rawFileName;
    this.athleteVideoDetails.transcoded_file_name = this.transcodedFileName;
    this.athleteVideoDetails.video_id = this.video_id;
    this.athleteVideoDetails.created_date = this.modificationDate;
    this.httpProvider.UpdateAthleteVideos(this.athleteVideoDetails).subscribe((data: any) => {
      setTimeout(() => {
        this.modalCtrl.dismiss();
        this.spinner.hide();
        let navigationExtras: NavigationExtras = {
          state: { enableEdit: true },
        };
        this.router.navigate(["profile/edit"], { replaceUrl: true, ...navigationExtras });
        this.dataSharing.isUpdatingVideos.next(true);
      }, 300);
    }, (error : any) => {
      this.spinner.hide();
      alert("Something went wrong");
      console.log(error);
    });
  }

  cancelUpload() {
    this.modalCtrl.dismiss();
  }

  CancelUpdate(){
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
}