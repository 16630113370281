import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

@Component({
  selector: 'app-share-copy-url',
  templateUrl: './share-copy-url.page.html',
  styleUrls: ['./share-copy-url.page.scss'],
})
export class ShareCopyUrlComponent {
  url: any;
  encryptedId: any;
  constructor(public modalCtrl: ModalController, public navParams: NavParams, private clipboard: Clipboard) {
    if (navParams != null) {
      if (navParams.data != null) {
        this.url = navParams.data[0];
        this.encryptedId = navParams.data[1];
      }
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ShareIconsClicked(args: any) {
    var location = window.location.href;
    var shortUrl = this.url.split("publicShare/")[1];
    if (args != null && args != undefined) {
      switch (args) {
        case 'facebook':
          //localhost  
          // var Fburl:any = "https://www.facebook.com/sharer/sharer.php?u=http%3A//localhost%3A8100/%23/publicShare/"+this.encryptedId;
          //Dev  
          // var Fburl: any = "https://www.facebook.com/sharer/sharer.php?u=http%3A//dev.ipostsports.net/%23/publicShare/" + shortUrl;
          //QA  
          //var Fburl: any = "https://www.facebook.com/sharer/sharer.php?u=http%3A//qa.ipostsports.net/%23/publicShare/" + shortUrl;
          //Prod  
          var Fburl: any = "https://www.facebook.com/sharer/sharer.php?u=https%3A//ipostprod-web1.azurewebsites.net/%23/publicShare/" + shortUrl;
          window.open(Fburl, '_blank');
          break;
        case 'twitter':
          //localhost  
          // var Twitterurl:any = "https://twitter.com/intent/tweet?text=http%3A//localhost%3A8100/%23/publicShare/"+this.encryptedId;
          //Dev
          // var Twitterurl: any = "https://twitter.com/intent/tweet?text=http%3A//dev.ipostsports.net/%23/publicShare/" + shortUrl;
          //QA
          //var Twitterurl: any = "https://twitter.com/intent/tweet?text=http%3A//qa.ipostsports.net/%23/publicShare/" + shortUrl;
          //Prod
          var Twitterurl: any = "https://twitter.com/intent/tweet?text=https%3A//ipostprod-web1.azurewebsites.net/%23/publicShare/" + shortUrl;
          window.open(Twitterurl, '_blank');
          break;
        case 'linkedin':
          //Dev  
          // var Linkedinurl: any = "https://www.linkedin.com/shareArticle?mini=true&url=http%3A//dev.ipostsports.net/%23/publicShare/" + shortUrl + "&title=iPost%20Sports%20Profile%20has%20been%20updated&summary=";
          //QA 
          //var Linkedinurl: any = "https://www.linkedin.com/shareArticle?mini=true&url=http%3A//qa.ipostsports.net/%23/publicShare/" + shortUrl + "&title=iPost%20Sports%20Profile%20has%20been%20updated&summary=";
          //Prod  
          var Linkedinurl: any = "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//ipostprod-web1.azurewebsites.net/%23/publicShare/" + shortUrl +"&title=iPost%20Sports%20Profile%20has%20been%20updated&summary=";
          window.open(Linkedinurl, '_blank');
          break;
        case 'email':
          //localhost  
          //var emailurl:any = "mailto:?body=http%3A//localhost%3A8100/%23/publicShare/"+this.encryptedId;
          //Dev
          // var emailurl: any = "mailto:?body=http%3A//dev.ipostsports.net/%23/publicShare/" + shortUrl;
          //QA
          //var emailurl: any = "mailto:?body=http%3A//qa.ipostsports.net/%23/publicShare/" + shortUrl;
          //Prod
          var emailurl: any = "mailto:?body=https%3A//ipostprod-web1.azurewebsites.net/%23/publicShare/" + shortUrl;
          window.open(emailurl, '_blank');
          break;
        case 'Copy':
          let shareURL: any = this.url;
          let toaster = document.getElementById('ShareUrl');
          if (toaster) {
            toaster.style.display = 'block';
          }          
          this.clipboard.copy(shareURL);

          const textArea = document.createElement('textarea');
          textArea.value = shareURL;
          document.body.appendChild(textArea);
          textArea.select();
          textArea.focus();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          console.log('Text copied to clipboard');
          setTimeout(() => {
            if (toaster) {
              toaster.style.display = 'none';
            }
          }, 1500);
          break;
      }
    }
  }
}
