import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { IbraintreeTransaction } from '../interfaces/account-interface';
import { AccountService } from '../providers/account-service.service';
import { HttpProvider } from '../providers/http-provider.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { ToasterService } from '../providers/toaster-service.service';

@Component({
  selector: 'app-eye-post-cancel-model',
  templateUrl: './eye-post-cancel-model.page.html',
  styleUrls: ['./eye-post-cancel-model.page.scss'],
})
export class EyePostCancelModelPage implements OnInit {
  paymentMode: string = "";
  athleteId: number = 0;
  clicked: any;
  clickedType: any;
  paymentModeType: any;
  public gridDataStore: Object[]|any;
  braintreeTransaction: IbraintreeTransaction = {
    cash: '',
    token: '',
    atheleteId: '',
    packageDescription: '',
    accountId: '',
    user: '',
    packageCode: '',
    isAddOnActive: false,
    athleteOldPackageCode: '',
    effectiveDate: null
  }
  isApplication: boolean = false;
  // public gridDataStore: Object[];
  constructor(public navCtrl: NavController, public httpProvider: HttpProvider,
    public modalCtrl: ModalController, private loadingCtrl: LoadingController,
    private alertCtrl: AlertController, public plt: Platform, public navParams: NavParams,
    private router: Router, private spinner: SpinnerService, private toaster : ToasterService, private accountService : AccountService) {
    if (navParams != null) {
      this.paymentMode = navParams.data.paymentMode;
      this.athleteId = navParams.data.atheleteId;
      this.clicked = navParams.data.clicked;
      this.braintreeTransaction.cash = navParams.data.cash;
      this.braintreeTransaction.packageCode = navParams.data.packageCode;
      this.braintreeTransaction.packageDescription = navParams.data.packageDescription;
      this.braintreeTransaction.atheleteId = navParams.data.atheleteId;
      this.braintreeTransaction.user = navParams.data.user;
      this.braintreeTransaction.isAddOnActive = navParams.data.isAddOnActive;
      this.braintreeTransaction.athleteOldPackageCode = navParams.data.athleteOldPackageCode;
    }
    if (this.plt.is('android') || this.plt.is('ios')) {
      this.isApplication = true;
    }
    else {
      this.isApplication = false;
    }
  }

  ngOnInit(): void {
    this.gridDataStore = localStorage.getItem('gridDataStore') != null ? JSON.parse(localStorage.getItem('gridDataStore') || '') : "";
  }

  async proceed() {
    this.modalCtrl.dismiss();
    this.spinner.show('Please wait...');
    if (this.braintreeTransaction.user != "parent") {
      this.httpProvider.braintreeAddOnCancelTransaction(this.braintreeTransaction).subscribe(async data => {
        if (data.ok = true && data.body == "success") {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Message',
            message: '"' + this.braintreeTransaction.packageDescription + ' has been successfully suspended."',
            buttons: ['OK']
          });
          (await alert).onDidDismiss().then(e => {
            this.modalCtrl.dismiss("Proceed");
            let navigationExtras: NavigationExtras = { state: { data: true } };
            this.router.navigate(['features'], navigationExtras);
          });
          (await alert).present();
        }
        else {
          this.modalCtrl.dismiss();
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: 'Something went wrong. Please try again later',
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(async e => {
            this.spinner.show('Please wait...');
            this.router.navigate(['package'], { replaceUrl: true });
            this.spinner.hide();
          });
        }
      },
        async (error: any) => {
          this.modalCtrl.dismiss();
          if (error.status == 401) {
          }
          else if(error && error.message){
            this.toaster.showFailureToast(error.message);
          }
          else {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: 'Something went wrong. Please try again later.',
              buttons: ['OK']
            });
            (await alert).onDidDismiss().then(e => {
            });
            (await alert).present();
          }
        })
    }
    else {
      let value =
      {
        accountId: this.accountService.getAccountId(),
        athleteId: this.braintreeTransaction.atheleteId,
        user: this.braintreeTransaction.user,
        subscriptionPackageDetails: localStorage.getItem('packageListStore') != null ? JSON.parse(localStorage.getItem('packageListStore') || '') : "",
        isMonthlyPlan: (localStorage.getItem('isMonthlyPlan') || ''),
      }
      this.httpProvider.braintreeParentAddOnCancelTransaction(value).subscribe(async data => {
        if (data.ok = true && data.body == "success") {
          this.spinner.hide();
          let alert = this.alertCtrl.create({
            header: 'Message',
            message: '"' + this.braintreeTransaction.packageDescription + ' has been successfully suspended."',
            buttons: ['OK']
          });
          (await alert).onDidDismiss().then(e => {
            this.modalCtrl.dismiss("Proceed");
            let navigationExtras: NavigationExtras = { state: { data: true } };
            this.router.navigate(['features'], navigationExtras);
          });
          (await alert).present();
        }
        else {
          this.spinner.hide();
          this.modalCtrl.dismiss();
          let alert = this.alertCtrl.create({
            header: 'Alert',
            message: 'Something went wrong. Please try again later',
            buttons: ['OK']
          });
          (await alert).present();
          (await alert).onDidDismiss().then(async e => {
            this.spinner.show('Please wait...');
            this.router.navigate(['package'], { replaceUrl: true });
            this.spinner.hide();
          });
        }
      },
        async (error: any) => {
          this.modalCtrl.dismiss();
          this.spinner.hide();
          if (error.status == 401) {
          }
          else if(error && error.message){
            this.toaster.showFailureToast(error.message);
          }
          else {
            let alert = this.alertCtrl.create({
              header: 'Alert',
              message: 'Something went wrong. Please try again later.',
              buttons: ['OK']
            });
            (await alert).present();
          }
        })
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  backButtonAction() {
    this.modalCtrl.dismiss();
  }
}