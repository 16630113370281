// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
  position: absolute;
  width: 100%;
  z-index: 100000;
  border-bottom: 5px solid #c01e2d;
  padding: 7px;
}

.ion-side-menu {
  background: black;
}

ion-toolbar {
  background: black;
  --background: black;
}

.custom-menu {
  --width: 250px;
}

.homeButton {
  float: right;
  color: white;
}

.bellButton {
  float: right;
  width: 30px;
  height: 30px;
  margin: 8px !important;
}

.txt {
  background-color: red;
  font-size: xx-small;
  position: absolute;
  padding: 6px;
  top: 5px;
  left: 11px;
  border-radius: 50%;
  color: white;
  text-align: center;
  min-width: 20px;
  min-height: 20px;
}

.icon {
  width: 30px;
  height: 30px;
  position: relative;
  float: right;
  margin-left: 15px !important;
}

ion-header.ion--main-header ion-toolbar img {
  margin-left: 10px !important;
  padding-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/_layout/homelayout/homelayout.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,gCAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,4BAAA;AACJ;;AAEA;EACI,4BAAA;EACA,2BAAA;AACJ","sourcesContent":[".app-header {\r\n    position: absolute;\r\n    width: 100%;\r\n    z-index: 100000;\r\n    border-bottom: 5px solid #c01e2d;\r\n    padding: 7px;\r\n}\r\n\r\n.ion-side-menu {\r\n    background: black;\r\n}\r\n\r\nion-toolbar{\r\n    background: black;\r\n    --background: black;\r\n}\r\n\r\n.custom-menu {\r\n    --width: 250px;\r\n}\r\n\r\n.homeButton {\r\n    float: right;\r\n    color: white;\r\n}\r\n\r\n.bellButton {\r\n    float: right;\r\n    width: 30px;\r\n    height: 30px;\r\n    margin: 8px !important;\r\n}\r\n\r\n.txt {\r\n    background-color: red;\r\n    font-size: xx-small;\r\n    position: absolute;\r\n    padding: 6px;\r\n    top: 5px;\r\n    left: 11px;\r\n    border-radius: 50%;\r\n    color: white;\r\n    text-align: center;\r\n    min-width: 20px;\r\n    min-height: 20px;\r\n}\r\n\r\n.icon {\r\n    width: 30px;\r\n    height: 30px;\r\n    position: relative;\r\n    float: right;\r\n    margin-left: 15px !important;\r\n}\r\n\r\nion-header.ion--main-header ion-toolbar img {\r\n    margin-left: 10px !important;\r\n    padding-top: 0px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
