// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model {
  background: white !important;
  --background: white;
  padding: 16px;
}

p, li {
  font-size: 13px;
  line-height: 20px;
  color: black;
}

h2 {
  color: black;
}

ol li {
  padding-bottom: 3px;
  font-weight: bold;
}

.toolbar {
  background: white;
  --background: white;
  position: fixed;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/privacy-policy/privacy-policy.page.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAGI;EACI,mBAAA;EACA,iBAAA;AAAR;;AAIA;EACI,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AADJ","sourcesContent":[".model {\r\n    background: white !important;\r\n    --background: white;\r\n    padding: 16px;\r\n}\r\n\r\np,li{\r\n    font-size: 13px;\r\n    line-height: 20px;\r\n    color: black;\r\n}\r\n\r\nh2{\r\n    color: black;\r\n}\r\n\r\nol{\r\n    li{\r\n        padding-bottom: 3px;\r\n        font-weight: bold;\r\n    }\r\n}\r\n\r\n.toolbar{\r\n    background: white;\r\n    --background: white;\r\n    position: fixed;\r\n    color: black;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
