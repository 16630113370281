import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { AccountService } from '../providers/account-service.service';
import { SpinnerService } from '../providers/spinner-service.service';
import { EditUploadDateModel } from '../edit-upload-date/edit-upload-date.page';
import { TitleModelPage } from '../title-model/title-model.page';
import { CategoryModelPage } from '../category-model/category-model.page';
import { HttpProvider } from '../providers/http-provider.service';
import { DataSharingService } from '../providers/data-sharing.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.page.html',
  styleUrls: ['./context-menu.page.scss'],
})
export class ContextMenuPage {
  isSynced: boolean = false;
  videoDetail: any;
  constructor(public accountService: AccountService, public popOverCtrl: PopoverController,
    public platform: Platform, public router: Router,
    public route: ActivatedRoute, private navParams: NavParams, private modalCtrl: ModalController,
    private spinner: SpinnerService, private alertCtrl: AlertController, private httpProvider: HttpProvider, public dataSharing: DataSharingService) {
    if (navParams != null && navParams.data != null && navParams.data != undefined) {
      this.videoDetail = navParams.data.details;
      this.isSynced = navParams.data.synced == "synced" ? true : false;
    }
  }

  async EditSyncedVideoTitle(args: any) {
    if (args != null) {
      this.popOverCtrl.dismiss();
      let data = {
        athlete_id: args.athlete_id,
        category: args.category_code,
        ipostTV: args.ipost_tv,
        videoTitle: args.video_title,
        videoThumbnailURL: args.url,
        rawFileName: args.raw_file_name,
        transcodedFileName: args.transcoded_file_name,
        isEdit: true,
        videoId: args.video_id,
        modificationTime: new Date(new Date(args.created_date) + " UTC")
      }

      let modal = await this.modalCtrl.create({ component: TitleModelPage, componentProps: data, cssClass: 'stripe-modal' });
      modal.present();
    }
  }

  async EditSyncedVideoCategory(args: any) {
    if (args != null) {
      this.popOverCtrl.dismiss();
      let data = {
        athlete_id: args.athlete_id,
        videoCategory: args.category_code,
        ipostTV: args.ipost_tv,
        videoTitle: args.video_title,
        videoThumbnailURL: args.url,
        rawFileName: args.raw_file_name,
        transcodedFileName: args.transcoded_file_name,
        isEdit: true,
        videoId: args.video_id,
        modificationTime: new Date(new Date(args.created_date) + " UTC")
      }
      let modal = await this.modalCtrl.create({ component: CategoryModelPage, componentProps: data, cssClass: 'stripe-modal', backdropDismiss: true, });
      modal.present();
    }
  }

  async DeleteVideo(args: any) {
    const alert = await this.alertCtrl.create({
      message: 'Do you want to delete this video?',
      cssClass: "alertMessageStyle",
      buttons: [
        {
          text: "NO", cssClass: "alertCancel", handler: () => {
            this.modalCtrl.dismiss();
            this.popOverCtrl.dismiss();
          }
        },
        {
          text: "YES",
          handler: () => {
            this.spinner.show("Deleting Video..");

            this.httpProvider.DeleteVideoDetails(args).subscribe(() => {
              let navigationExtras: NavigationExtras = {
                state: { enableEdit: true },
              };
              this.router.navigate(["profile/edit"], { replaceUrl: true, ...navigationExtras });
              this.dataSharing.isUpdatingVideos.next(true);
              this.modalCtrl.dismiss();
              this.spinner.hide();
            }),
              async (error: any) => {
                this.spinner.hide();
                this.modalCtrl.dismiss();
                this.popOverCtrl.dismiss();
                let alert = this.alertCtrl.create({
                  header: 'Alert',
                  message: "Something went wrong. Please try again later",
                  buttons: ['Ok']
                });
                (await alert).present();
              };
          },
          cssClass: "alertOk",
        },
      ],
    });
    await alert.present();
  }
}
