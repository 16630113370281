// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
  position: absolute;
  width: 100%;
  z-index: 100000;
  border-bottom: 5px solid #c01e2d;
}

ion-toolbar {
  background: black;
  --background: black;
}`, "",{"version":3,"sources":["webpack://./src/app/_layout/commonlayout/commonlayout.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,gCAAA;AACJ;;AACA;EACI,iBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".app-header {\r\n    position: absolute;\r\n    width: 100%;\r\n    z-index: 100000;\r\n    border-bottom: 5px solid #c01e2d;\r\n}\r\nion-toolbar{\r\n    background: black;\r\n    --background: black;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
