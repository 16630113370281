// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  text-align: center;
  font-size: 11px;
  font-style: italic;
  color: white;
  background: #BE1E2D;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/home-footer/home-footer.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,OAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;AAAJ","sourcesContent":["\r\n  .footer {\r\n    text-align: center;\r\n    font-size: 11px;\r\n    font-style: italic;\r\n    color: white;\r\n    background: #BE1E2D;\r\n    left: 0;\r\n    bottom: 0;\r\n    position: absolute;\r\n    z-index: 10;\r\n    display: block;\r\n    width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
