import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { TokenvalidatorService } from "./tokenvalidator.service";

@Injectable({
  providedIn: "root",
})
export class AuthguardGuard  {
  constructor(public router: Router, public token: TokenvalidatorService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url: string = state.url;

    const accountId = localStorage.getItem('accountId') != null ? JSON.parse(localStorage.getItem('accountId') || '') :"";

    if (accountId === undefined || accountId === null || accountId === "") {
      if (url === "/login" || url === "/signup") {
        return true;
      }

      this.router.navigate(["/login"], { replaceUrl: true });
    }

    if (
      accountId !== undefined &&
      accountId !== null &&
      accountId !== "" &&
      (url === "/login" || url === "/signup")
    ) {
      this.router.navigate(["/profile/view"], { replaceUrl: true });
    }

    return true;
  }

  canLoad(): boolean {
    return true;
  }
}