import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AccountSettingsPageRoutingModule } from './account-settings-routing.module';

import { AccountSettingsPage } from './account-settings.page';
import { DatePickerModule, DateTimePickerModule, DateRangePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { MultiSelectModule, AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { HomeFooterModule } from '../home-footer/home-footer.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AccountSettingsPageRoutingModule,
    DatePickerModule,
    MultiSelectModule,
    DateTimePickerModule,
    ScheduleAllModule,
    AutoCompleteModule,
    ChartAllModule,
    DateRangePickerAllModule,
    MaskedTextBoxModule,
    HomeFooterModule
  ],
  declarations: [AccountSettingsPage]
})
export class AccountSettingsPageModule { }
