import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ViewProfilePageRoutingModule } from './view-profile-routing.module';

import { DatePickerModule, DateTimePickerModule, DateRangePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { MultiSelectModule, AutoCompleteModule, DropDownListAllModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ViewProfilePage } from './view-profile.page';
import { HomeFooterModule } from '../home-footer/home-footer.module';
import { ComponentsModule } from '../component/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewProfilePageRoutingModule,
    DatePickerModule,
    MultiSelectModule,
    TabModule,
    NumericTextBoxModule,
    DateTimePickerModule,
    ScheduleAllModule,
    AutoCompleteModule,
    ChartAllModule,
    DropDownListModule,
    DropDownListAllModule,
    DateRangePickerAllModule,
    HomeFooterModule,
    ComponentsModule
  ],
  declarations: [ViewProfilePage],
  providers: [SocialSharing]
})
export class ViewProfilePageModule {}
